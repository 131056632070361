// alpha
import { Auth } from 'aws-amplify'
export const CognitoConfig = {
    Auth: {
        region: "eu-west-1",
        userPoolId: "eu-west-1_8mhsqFeqj",
        userPoolWebClientId: "7cql68bjef2rrt0u021c59tha1"
    },
    API: {
        endpoints: [
            {
                name: "Backend",
                endpoint: "https://ubi-prod.ubi.datafolio.org/",
                custom_header: async () => {
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                }
            },
        ]
    }
}
