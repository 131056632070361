import React from 'react'

const PrivacyPolicyEN = () => {
        return (
            <div style={{'margin-left': '5%'}}>
                <table width={"90%"} cellSpacing={0} cellPadding={7}>
                    <colgroup>
                        <col width={629} />
                    </colgroup>
                    <tbody>
                    <tr>
                        <td width={629} valign="top">
                        </td>
                    </tr>
                    <tr>
                        <td width={629} valign="top">
                            <p align="center">
                                <strong>
                                    INFORMATION ON THE PROCESSING OF PERSONAL DATA
                                </strong>
                            </p>
                            <p align="center">
                                <strong>PURSUANT TO EU REGULATION NO. 2016/679</strong>
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table width={"90%"} cellSpacing={0} cellPadding={7}><tr><td>
                <p lang="it-IT">
                    In application of the legislation on the processing of personal data in the
                    field of privacy and the EU Regulation no. 2016/679 (hereinafter GDPR) we
                    inform you that, as a result of signing the General Conditions of Use of
                    the App of Datafolio S.a.s. ("Developer"), with registered office in 165
                    avenue de Bretagne - 59000 Lille (France) - P.Iva FR31877629493, during the
                    course of the contractual relationship the latter will collect and process,
                    as data controller, some of your personal data for the purpose of providing
                    the requested services. The data collected will be processed on the basis
                    of the principles of correctness, lawfulness and transparency, as well as
                    in compliance with the principle of data minimization. In this regard, we
                    request your express consent for certain types of processing of your
                    personal data.
                </p>
                <p>
                    <u><strong>1. Definitions</strong></u>
                </p>
                <p lang="it-IT" align="justify">
                    In order to facilitate the understanding of the notice, some definitions of
                    Article 4 of the GDPR are given.
                </p>
                <p align="justify">
                    In particular, we mean by:
                </p>
                </td></tr></table>
                <table width={"90%"} cellSpacing={0} cellPadding={7}>
                    <colgroup>
                        <col width={184} />
                        <col width={430} />
                    </colgroup>
                    <tbody>
                    <tr valign="top">
                        <td width={184}>
                            <p lang="it-IT" align="justify">
                                <u><strong>Subject</strong></u>
                                :
                            </p>
                        </td>
                        <td width={430}>
                            <p lang="it-IT">
                                the identified or identifiable natural person to whom the
                                personal data refer.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={184}>
                            <p lang="it-IT">
                                <u><strong>Consent of the subject</strong></u>
                                :
                            </p>
                        </td>
                        <td width={430}>
                            <p lang="it-IT">
                                any manifestation of the free, specific, informed and
                                unambiguous will of the data subject, whereby the data
                                subject signifies his or her assent, by way of a statement
                                or unambiguous affirmative action, to personal data
                                relating to him or her being processed.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={184}>
                            <p lang="it-IT">
                                <u><strong>Treatment</strong></u>
                                :
                            </p>
                        </td>
                        <td width={430}>
                            <p lang="it-IT">
                                any operation or set of operations which is performed upon
                                personal data or sets of personal data, whether or not by
                                automatic means, such as collection, recording,
                                organization, structuring, storage, adaptation or
                                alteration, retrieval, consultation, use, disclosure by
                                transmission, dissemination or otherwise making available,
                                alignment or combination, restriction, erasure or
                                destruction.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={184}>
                            <p lang="it-IT">
                                <u><strong>Personal Data</strong></u>
                                :
                            </p>
                        </td>
                        <td width={430}>
                            <p lang="it-IT">
                                any information relating to an identified or identifiable
                                natural person ('data subject'); an identifiable person is
                                one who can be identified, directly or indirectly, by
                                reference in particular to an identifier such as a name, an
                                identification number, location data, an online identifier
                                or to one or more factors specific to his or her physical,
                                physiological, genetic, mental, economic, cultural or
                                social identity.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={184}>
                            <p lang="it-IT">
                                <u><strong>Special Personal Data:</strong></u>
                            </p>
                        </td>
                        <td width={430}>
                            <p lang="it-IT">
                                personal data revealing racial or ethnic origin, political
                                opinions, religious or philosophical beliefs, or trade
                                union membership, as well as processing of genetic data,
                                biometric data intended to uniquely identify a natural
                                person, data concerning a person's health or sex life or
                                sexual orientation or data relating to criminal convictions
                                or investigations.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={184}>
                            <p lang="it-IT">
                                <u><strong>Information</strong></u>
                                <strong>: </strong>
                            </p>
                        </td>
                        <td width={430}>
                            <p lang="it-IT">
                                the information that the data controller must provide to
                                each data subject, either verbally or in writing when the
                                data are collected from the data subject or from third
                                parties. The information must specify briefly and
                                colloquially what the purposes and methods of processing
                                are; whether or not the data subject is obliged to provide
                                the data; what the consequences are if the data are not
                                provided; to whom the data may be communicated or
                                disseminated; what the rights of the data subject are; who
                                the data controller and data processor, if any, are and
                                where they can be reached (address, telephone, fax, etc.).
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={184}>
                            <p lang="it-IT">
                                <u><strong>Controller of the treatment</strong></u>
                                <strong>:</strong>
                            </p>
                        </td>
                        <td width={430}>
                            <p lang="it-IT">
                                the natural or legal person, public authority, agency or
                                other body which alone or jointly with others determines
                                the purposes and means of the processing of personal data.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={184}>
                            <p lang="it-IT">
                                <u>
                                    <strong>
                                        Responsible for processing (Processor):
                                    </strong>
                                </u>
                            </p>
                        </td>
                        <td width={430}>
                            <p lang="it-IT">
                                the natural or legal person, public authority, service or
                                other body that processes personal data on behalf of the
                                Controller.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={184}>
                            <p lang="it-IT">
                                <u><strong>Data Protection Officer (DPO):</strong></u>
                            </p>
                        </td>
                        <td width={430}>
                            <p lang="it-IT" align="justify">
                                The Data Protection Officer (DPO), is a figure introduced
                                by the GDPR. His main responsibility is to observe, assess
                                and organise the management of the processing of personal
                                data (and therefore their protection) within a company
                                (whether public or private), so that they are processed in
                                compliance with European and national privacy regulations.
                            </p>
                            <p lang="it-IT" align="justify">
                                The appointment of a DPO within a company is mandatory when
                                the following conditions are met:
                            </p>
                            <ul>
                                <li>
                                    <p lang="it-IT">
                                        The processing is carried out by a public authority
                                        or a public body, excluding courts when they are
                                        acting in their judicial capacity;
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        the core activities of the controller or processor
                                        consist of processing operations which, by virtue
                                        of their nature, their scope and/or their purposes,
                                        require the regular and systematic monitoring of
                                        data subjects on a large scale; or
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        the main activities of the controller or processor
                                        consist of processing, on a large scale, special
                                        categories of personal data or data relating to
                                        criminal convictions and offences.
                                    </p>
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={184}>
                            <p>
                                <u><strong>Profiling:</strong></u>
                            </p>
                        </td>
                        <td width={430}>
                            <p lang="it-IT">
                                any form of automated processing of personal data
                                consisting of the use of personal data to evaluate certain
                                personal aspects relating to a natural person, in
                                particular to analyze or predict aspects of that person's
                                professional performance, economic situation, health,
                                personal preferences, interests, reliability, behavior,
                                location or movements.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={184}>
                            <p lang="it-IT">
                                <u><strong>Pseudonymization</strong></u>
                                <strong>:</strong>
                            </p>
                        </td>
                        <td width={430}>
                            <p lang="it-IT">
                                processing of personal data in such a way that they can no
                                longer be attributed to a specific data subject without the
                                use of additional information, provided that such
                                information is stored separately and subject to technical
                                and organisational measures to ensure that the data are not
                                attributed to an identified or identifiable natural person:
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={184} height={60}>
                            <p>
                                <u><strong>Violation of personal data:</strong></u>
                            </p>
                        </td>
                        <td width={430}>
                            <p lang="it-IT">
                                a breach of security leading to the accidental or unlawful
                                destruction, loss, alteration, unauthorized disclosure of,
                                or access to, personal data transmitted, stored or
                                otherwise processed.
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table width={"90%"} cellSpacing={0} cellPadding={7}><tr><td>
                <p lang="it-IT"><u><strong>
                    2. Nature of the data processed and purpose of processing
                </strong></u></p>
                </td></tr></table>
                <table width={"90%"} cellSpacing={0} cellPadding={7}>
                    <colgroup>
                        <col width={19} />
                        <col width={595} />
                    </colgroup>
                    <tbody>
                    <tr valign="top">
                        <td width={19}>
                            <p align="justify">
                                <strong>2.1</strong>
                            </p>
                        </td>
                        <td width={595}>
                            <p>
                                <strong>
                                    Processing of personal data for commercial
                                    intermediation and risk prevention purposes
                                </strong>
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                The processing of your personal data is aimed at the
                                fulfilment by Datafolio of business purposes such as the
                                activities necessary for the download and activation of the
                                Datafolio app, the provision of services requested by the
                                interested party via the Datafolio app, the preparation,
                                conclusion of proposals/service contracts, supply and other
                                services, the exercise and defense of Datafolio's rights,
                                the fulfilment of other specific legal or contractual
                                obligations; the analysis of new markets; internal
                                management and control; statistical activities, risk
                                prevention.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                In particular, the personal data provided by you
                                (identification and contact data) and/or collected and
                                transmitted by your mobile device via the Datafolio mobile
                                app, also relating to your geolocation, are processed by
                                Datafolio and the processing of the data is aimed at the
                                provision of the requested telematic services such as,
                                specifically:
                            </p>
                            <ul>
                                <li>
                                    <p lang="it-IT">
                                        knowledge of the hazard index in the area where the
                                        mobile device is located or in relation to the
                                        activity and/or route planned based on the
                                        information you have provided. The hazard index is
                                        defined as the risk of certain events occurring
                                        such as accidents, theft, robbery, damage to
                                        vehicles, weather, contagion, personal and/or
                                        health needs, etc. The percentage risk of
                                        occurrence is determined exclusively on the basis
                                        of statistical data collected and processed
                                        directly by Datafolio;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        access to technical assistance and/or emergency
                                        services;
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        the provision of services related to user mobility,
                                        logistics and assistance services, information
                                        services on suppliers and other business partners;
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        the use of real-time reporting services on weather
                                        conditions, traffic estimates, points of interest,
                                        dedicated travel routes, the presence of health
                                        risk areas or areas exposed to other types of risk,
                                        and the hazard index of a specific area;
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        the identification, in real time, automatically or
                                        on request, of health facilities, shops, service
                                        centres and other points of interest (restaurants,
                                        petrol stations, ATMs, pharmacies, etc.) that have
                                        an agreement in the vicinity of the user's area and
                                        the identification of their contact details as well
                                        as the route to reach them;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        the storage of the user's location and/or where his
                                        vehicle is located limited to the provision of the
                                        vehicle recovery service;
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        making logistical arrangements and exchanging other
                                        communications with you;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        the production of statistical reports on the user
                                        in order to optimise the provision of services;
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        the provision of services aimed at geolocating the
                                        user and finding the vehicle and/or means of
                                        transport and/or locomotion being used;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        respond to comments and reports submitted by users;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        send e-mail messages in order to improve the
                                        service offered;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        send technical communications (e.g. notification of
                                        service interruptions);
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        the provision of information and statistics on
                                        driving (road and/or pedestrian), driving behaviour
                                        or driving behaviour assessed as a whole in
                                        relation to risk prevention.
                                    </p>
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p>
                                <u>
                                    <strong>
                                        Existence of an automated decision-making process
                                    </strong>
                                </u>
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                        </td>
                        <td width={595}>
                            <p lang="it-IT" align="justify">
                                <strong>
                                    We inform you that Datafolio S.a.s., in order to
                                    provide the aforementioned services, needs to carry out
                                    analysis activities, through an automated
                                    decision-making process, by analysing the mobility data
                                    collected by the smartphone sensors.
                                </strong>
                            </p>
                            <p lang="it-IT" align="justify">
                                <strong>
                                    This process is carried out using pre-established
                                    algorithms and limited to the aforementioned needs of
                                    commercial intermediation and risk prevention.
                                </strong>
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                The data processed also include, by way of example,
                                personal data (e.g. name, surname, mobile phone number,
                                e-mail), information on the device used (e.g. operating
                                system installed, model, etc.). In addition, in order to
                                allow the download of the Datafolio app and exclusively for
                                reasons related to the need to provide the requested
                                services, access to further resources of the device may be
                                required, such as, for example, position, background
                                localisation (which allows the continuous tracking of your
                                movements on the various means of transport even when the
                                app is closed or not visible), SMS, photos/media
                                elements/files, information on the Internet connection,
                                device serial number, Mobile App version downloaded, etc.
                                The Datafolio app may use the device's camera if the
                                acquisition and recognition of a QR code is required in
                                order to access and/or use services.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                For the purposes of the services available via the
                                Datafolio app, the legal basis for the processing is the
                                contract, i.e. the concluding act with which the user
                                decides to make use of the services offered by Datafolio
                                via the app at the time of its installation.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                Personal data is collected directly or through other
                                parties involved in the relationship with you (such as, for
                                example, contractors of contracts to which you are the
                                named party, beneficiary, co-obligor, service providers and
                                other business partners, etc.). Your data may also be
                                collected through other operators directly or indirectly
                                connected to Datafolio; subjects who, in order to satisfy
                                your requests (a request for assistance, stipulation of an
                                agreement, advice from a specialist, etc.), provide
                                commercial information; associations and consortia
                                belonging to the commercial sector and/or other connected
                                subjects:
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                The furnishing of your personal data is necessary for the
                                provision of commercial services/products and/or compulsory
                                under provisions of law, regulation or EU legislation. Any
                                refusal to provide your data will make it impossible for
                                Datafolio to perform the service. Since your personal data
                                processed for the above-mentioned purposes may also include
                                any special data and/or data subject to special protection,
                                strictly inherent to the provision of the above-mentioned
                                commercial services/products, we ask you to express your
                                consent to the processing.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19} height={98}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                We also inform you that your personal data, as the case may
                                be, can and must be communicated to other subjects
                                belonging to the commercial sector and/or related with a
                                merely organisational function to Datafolio who operate in
                                Italy or abroad (European Union countries and to
                                non-European Union countries that guarantee an adequate
                                level of protection substantially equivalent to that
                                guaranteed within the Union in accordance with the
                                applicable legal provisions, subject to the stipulation of
                                the standard contractual clauses provided for by the
                                European Commission) as autonomous data controllers for the
                                purposes set out above. By way of example and without
                                limitation, the subjects to whom personal data may be
                                communicated are: merchants, suppliers, consultants,
                                technicians in general and entities/companies that issue
                                services/products related or associated with the
                                contractual services, intermediaries, producers, commercial
                                brokers and other channels for the acquisition of
                                contracts; shopkeepers, cooperatives, management companies,
                                entities, law firms for the management of any complaints or
                                disputes, lawyers, doctors, experts, service companies
                                entrusted with the management, stipulation and
                                consolidation of conventions; contracted activities
                                (possible partners of Datafolio) computer and telematic
                                services or archiving companies; support companies for
                                management activities, including postal service companies;
                                auditing and consulting companies; commercial information
                                companies for financial and various risks, service
                                companies for the control of fraud, credit recovery
                                companies; associative and consortia bodies belonging to
                                the commercial sector for reciprocal exchanges with all the
                                consortium companies; obligatory social commercial
                                management bodies, etc. ; Ministry of Economy and Finance -
                                Tax Registry, judiciary, law enforcement, consortia and
                                agricultural activities.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                Therefore, the consent that we ask you to give also
                                concerns the activities carried out by these subjects,
                                limited to what is strictly within their competence in
                                relation to the specific relationship between you and
                                Datafolio. In addition, the data may be made anonymous and
                                processed on a statistical basis in order to obtain
                                information instrumental to the provision of commercial
                                intermediation and/or risk prevention services and to
                                evaluate and improve the quality of the services and/or
                                performances offered within the scope of the aforementioned
                                activities.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19} height={37}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                Please note that without your consent to the use of your
                                data we will not be able to provide you with the above
                                services/products.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="justify">
                                <strong>2.2</strong>
                            </p>
                        </td>
                        <td width={595}>
                            <p>
                                <strong>
                                    Processing of personal data for insurance mediation
                                    purposes
                                </strong>
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                The personal data provided by you (identification and
                                contact data) and/or communicated by Datafolio's partner
                                insurance companies (concerning the vehicle and information
                                regarding the type of insurance policy taken out, etc.)
                                and/or detected and transmitted by your mobile device via
                                the Datafolio mobile app, such as, for example, geolocation
                                and/or background localisation (which allows for continuous
                                tracking of your movements on the various insurance
                                policies, etc.). ) and/or collected and transmitted by your
                                mobile device via the Datafolio mobile app, such as, for
                                example, geolocation and/or background localisation (which
                                allows the continuous tracking of your movements on the
                                various means of transport even when the app is closed or
                                not visible) are processed by Datafolio (insurance
                                intermediary in the European Union registered in the
                                special list attached to the R.U.I.) and the processing of
                                the data is aimed at the geolocation (GPS, Wi-FI, GSM
                                network) of the user and/or the vehicle and/or means of
                                transport and/or locomotion used.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                This geolocation system serves the following purposes:
                            </p>
                            <ul>
                                <li>
                                    <p lang="it-IT">
                                        location of the user and/or the vehicle and/or
                                        means of transport and/or locomotion used and
                                        transmission of the detected position: this is done
                                        in order to give logistical instructions and
                                        exchange other real-time communications with the
                                        driver and/or user;
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        production of a driving report containing
                                        information on route and travel time, average
                                        speed, distances and fuel consumption, as well as
                                        other operations carried out with the vehicle
                                        and/or means of transport and/or locomotion and/or
                                        by the user;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        implementation of insurance brokerage services and
                                        in particular:
                                    </p>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <p lang="it-IT">
                                        provide the services presented on the Datafolio
                                        mobile app;
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        allow the purchase of insurance products and/or
                                        services chosen by the user on the Mobile App,
                                        offered by insurance companies and/or other
                                        partners of Datafolio and expressly listed on the
                                        website;
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        provide the insurance companies and/or other
                                        partners of Datafolio with the information
                                        necessary for the provision of the services
                                        requested by you or agreed with you;
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        legal fulfilments in compliance with legal
                                        obligations, regulations or EU standards with which
                                        Datafolio is obliged to comply;
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        contractual fulfilments necessary for the proper
                                        management, execution and conclusion of existing,
                                        defined or to be defined relationships, such as:
                                        studies of insurance risks, preparation and
                                        conclusion of insurance policies, collection of
                                        premiums, management of insurance claims or payment
                                        of other benefits, protection of your rights as an
                                        insured person;
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        logistics and support services;
                                    </p>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <p>
                                        respond to comments and reports submitted by users;
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        send e-mail messages in order to improve the
                                        service offered;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>
                                            use in the context of 'pay as you drive'
                                            (PAID).
                                        </strong>
                                    </p>
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p>
                                <u>
                                    <strong>
                                        Existence of an automated decision-making process
                                    </strong>
                                </u>
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                <strong>
                                    We would like to inform you that Datafolio S.a.s., in
                                    order to assess and calculate the insurance premium and
                                    its possible adjustment, needs to carry out analytical
                                    activities, through an automated decision-making
                                    process, by analysing the mobility data collected by
                                    the smartphone sensors.
                                </strong>
                            </p>
                            <p lang="it-IT">
                                <strong>
                                    This process is carried out using pre-determined
                                    algorithms limited to the aforementioned insurance
                                    requirement.
                                </strong>
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p>
                                For the exclusive insurance purposes of the policy, your
                                express consent is required.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                Should it be necessary to handle sensitive personal data
                                strictly inherent to the provision of insurance services
                                and/or products, you will be asked for specific consent at
                                the time of the collection of such data, which shall be
                                processed within the limits strictly necessary in practice,
                                by the relative general authorizations issued by the Data
                                Protection Authority. Such data may, however, be
                                subsequently acquired and processed by insurance companies
                                and/or other partners of Datafolio, always within the
                                limits in which such processing is instrumental to the
                                specific purpose pursued by the operation or services
                                requested.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p>
                                In pursuance of the purposes described in point 2.2, the
                                data may be communicated to some of the following entities:
                            </p>
                            <ul>
                                <li>
                                    <p lang="it-IT">
                                        Settlement service companies; service companies
                                        entrusted with the management, settlement and
                                        payment of claims, including an operational
                                        assistance centre, a legal aid consultancy, a
                                        contracted clinic; computer and telematic services
                                        or archiving companies; postal service companies
                                        (for the transmission, mailing, transport and
                                        sorting of communications to customers); auditing
                                        and consulting companies; commercial information
                                        companies for financial risks; fraud control
                                        service companies; debt collection companies;
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        Companies providing insurance services with which
                                        Datafolio has entered into/will enter into
                                        commercial agreements (mainly insurance companies
                                        and insurance intermediaries) for the provision of
                                        the services you have requested. The up-to-date
                                        list of insurance companies and insurance
                                        intermediaries and partners will be published in
                                        special sections/pages;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Companies providing cloud services and/or other IT
                                        services which Datafolio uses;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Associations (ANIA) and consortia specific to the
                                        insurance sector;
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        IVASS, Ministry of Industry, Trade and Crafts,
                                        CONSAP, UCI, Ministry of Labour and Social Security
                                        and other databases to which the communication of
                                        data is mandatory.
                                    </p>
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                Furthermore, given that the data provided, as part of the
                                existing or future insurance relationship, may be used to
                                provide the service of quoting and issuing policies, to
                                this end they may be processed and compared with other
                                legitimately available databases, taking appropriate
                                precautions to ensure the utmost confidentiality of the
                                data. In addition, the data may be made anonymous and
                                processed on a statistical basis in order to obtain
                                information instrumental to the provision of insurance
                                brokerage and/or risk prevention services and to assess and
                                improve the quality of the services and/or performances
                                offered within the scope of the aforementioned activities.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p lang="it-IT" align="justify">
                                <strong>2.3</strong>
                            </p>
                        </td>
                        <td width={595}>
                            <p>
                                <strong>
                                    Processing of personal data for market research and/or
                                    promotional purposes
                                </strong>
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                If you give specific and separate consent, your data may be
                                disclosed to third party companies with which Datafolio has
                                entered into business partnerships, such as marketing
                                companies, co-marketing, advertising platforms. In this
                                case, thanks to your consent, these subjects - acting as
                                autonomous data controllers, providing separate information
                                - may use your data to send you confidential offers and
                                proposals. Your data will not be disseminated, made
                                available or given in consultation in any form to subjects
                                other than those indicated in this paragraph or to
                                unspecified subjects.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                The data collected may be processed by Datafolio, subject
                                to the express and free consent of the person concerned,
                                for the following purposes
                            </p>
                            <ul>
                                <li>
                                    <p>
                                        purposes of promoting value-added services (e.g.:
                                        weather information, traffic, etc.);
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        commercial communication, the offer of products or
                                        services, whether own or of third parties, the
                                        sending of advertising material, the performance of
                                        market research and profiling activities by
                                        Datafolio. Such offers may be conveyed via e-mail,
                                        traditional channels (e.g. traditional mail) and
                                        also through the use of telephone with operator
                                        and/or automated systems (e.g. SMS, notifications
                                        on the Datafolio app);
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        improve and develop the services provided to the
                                        user through electronic processing of the user's
                                        behaviour and needs in order to identify and
                                        propose more advantageous offers and products of
                                        greater interest to the Policyholder, also on the
                                        basis of the assessment of the insurance risk
                                        linked to the mileage and driving style of the
                                        insured vehicle.
                                    </p>
                                </li>
                            </ul>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table width={"90%"} cellSpacing={0} cellPadding={7}><tr><td>
                <p>
                    <u>
                        <strong>3. Modalities of processing and data retention period</strong>
                    </u>
                </p>
                </td></tr></table>
                <table width={"90%"} cellSpacing={0} cellPadding={7}>
                    <colgroup>
                        <col width={19} />
                        <col width={595} />
                    </colgroup>
                    <tbody>
                    <tr valign="top">
                        <td width={19}>
                            <p align="justify">
                                3.1
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                Your data shall be processed by Datafolio - in its capacity
                                as Data Controller - by means of operations or series of
                                operations, as envisaged by current legislation. The
                                processing will also take place with the aid of electronic
                                or automated means and will be carried out directly by the
                                Data Controller and by subjects external to this
                                organisation in Italy or abroad, in the manner provided for
                                by law. These subjects are, in turn, committed to
                                processing the data using only methods and procedures
                                strictly necessary for the specific purposes indicated in
                                this statement and in accordance with the law. We also
                                inform you that, within the Company, the data will be
                                processed by employees and collaborators within the scope
                                of their respective functions and in accordance with the
                                instructions received, always and only for the achievement
                                of the specific purposes indicated in this statement.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="justify">
                                3.2
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                All personal data concerning you shall be stored by
                                Datafolio for the time necessary and functional to the
                                management of the purposes of the processing indicated
                                therein and in any case up to a maximum of six months for
                                data related to the recording of your travels and movements
                                for purposes of commercial intermediation and risk
                                prevention and up to a maximum of five years for data
                                related to the provision of the services underlying the
                                insurance policy, including the duration of the trips and
                                related costs.
                            </p>
                            <p lang="it-IT">
                                The data that need not be stored due to legal obligations
                                or the legitimate interests of the Data Controller shall be
                                deleted after the expiry of the terms connected to any
                                legal storage obligations or prescriptive terms starting
                                from the date of cessation of the requirements related to
                                the purposes indicated in this information notice; the data
                                shall also be processed with the aid of electronic and
                                automated IT means, by means of terminals and personal
                                computers connected in a network with a mainframe and, in
                                any case, by means of instruments suitable to guarantee
                                security and confidentiality. In addition, the data may be
                                made anonymous and processed on a statistical basis in
                                order to obtain information instrumental to the provision
                                of commercial and/or insurance brokerage and risk
                                prevention services and to assess and improve the quality
                                of the services and/or performances offered within the
                                scope of the aforementioned activities.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                Datafolio employs external persons/entities for the
                                technological management of the data processed via the
                                Datafolio app. These persons shall act as external
                                controllers of the respective processing delegated by the
                                Company.
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table width={"90%"} cellSpacing={0} cellPadding={7}><tr><td>
                <p>
                    <u><strong>4. Data Controller, Data Processor</strong></u>
                </p>
                </td></tr></table>
                <table width={"90%"} cellSpacing={0} cellPadding={7}>
                    <colgroup>
                        <col width={19} />
                        <col width={595} />
                    </colgroup>
                    <tbody>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                The Data Controller of your personal data is Datafolio,
                                with registered office at 165 avenue de Bretagne - 59000
                                Lille (France), certified email address:
                                datafolio.sas@pec.it, in the person of its Legal
                                Representative pro tempore.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p>
                                The data provided by you may be processed by employees and
                                collaborators of Datafolio who have been duly appointed by
                                the Data Controller as being responsible for processing.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT" align="justify">
                                The list of Data Controllers and Processors is constantly
                                updated and can be requested by sending a communication to
                                the following e-mail address: info@datafolio.io
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p>
                                Datafolio has appointed a Data Protection Officer (DPO),
                                who can be contacted at the following e-mail address:
                                dpo@datafolio.io
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table width={"90%"} cellSpacing={0} cellPadding={7}><tr><td>
                <p lang="it-IT">
                    <u><strong>5. Rights of the Data Subject</strong></u>
                </p>
                </td></tr></table>
                <table width={"90%"} cellSpacing={0} cellPadding={7}>
                    <colgroup>
                        <col width={19} />
                        <col width={595} />
                    </colgroup>
                    <tbody>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                The Regulation gives you the right to exercise specific
                                rights, including the right to ask the data controller:
                            </p>
                            <ul>
                                <li>
                                    <p lang="it-IT">
                                        Confirmation of whether or not your personal data
                                        are being processed and, if so, to obtain access to
                                        them (right of access);
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        The rectification of inaccurate personal data or
                                        the integration of incomplete personal data (right
                                        of rectification);
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        The deletion of the data itself, if one of the
                                        reasons provided for in the Regulation exists
                                        (right to be forgotten);
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        The limitation of processing when one of the cases
                                        provided for in the Regulation applies (right of
                                        restriction);
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        To receive in a structured, commonly used and
                                        machine-readable format the personal data you have
                                        provided to the Data Controller and to transmit
                                        such data to another data controller (right to
                                        portability);
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        To have access to the categories of third parties
                                        that access your personal data and to withdraw
                                        consent to such processing, without prejudice to
                                        the lawfulness of the processing based on the
                                        consent given before the withdrawal;
                                    </p>
                                </li>
                                <li>
                                    <p lang="it-IT">
                                        To withdraw consent to the processing of your data,
                                        at any time, without prejudice to the lawfulness of
                                        the processing based on the consent given prior to
                                        the withdrawal and to object at any time to the
                                        processing for marketing and profiling purposes
                                        (right to object).
                                    </p>
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                By installing the Datafolio app on your device, you consent
                                to the processing of your data via the app. The consent
                                provided can be revoked via the dedicated services.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                At any time you can easily stop all information gathering
                                by the app by uninstalling the app itself.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p>
                                The exercise of rights is not subject to any formal
                                constraints and is free of charge.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                To exercise your rights, you can send your requests at any
                                time to the following e-mail address: datafolio.sas@pec.it.
                            </p>
                        </td>
                    </tr>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                Also in consideration of future changes that may occur in
                                the applicable privacy legislation, Datafolio may
                                supplement and/or update all or part of this Policy. It is
                                understood that any amendments, supplements or updates will
                                be communicated to you in accordance with applicable law.
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table width={"90%"} cellSpacing={0} cellPadding={7}><tr><td>
                <p lang="it-IT">
                    <u><strong>6. Complaint to the Data Protection Authority</strong></u>
                </p>
                </td></tr></table>
                <table width={"90%"} cellSpacing={0} cellPadding={7}>
                    <colgroup>
                        <col width={19} />
                        <col width={595} />
                    </colgroup>
                    <tbody>
                    <tr valign="top">
                        <td width={19}>
                            <p align="right">
                                •
                            </p>
                        </td>
                        <td width={595}>
                            <p lang="it-IT">
                                <a name="_Hlk85128069" />
                                Without prejudice to any other administrative or
                                jurisdictional recourse. You have the right to lodge a
                                complaint with the Data Protection Authority, following the
                                procedures and instructions published on the official
                                website of the Authority at www.garanteprivacy.it, if you
                                believe that the processing of your personal data violates
                                the Regulation.
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }

export default PrivacyPolicyEN
