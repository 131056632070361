import {ConsentSettings, Flavour} from "./index";
import api, {Consents, Profile} from "../api";
import MainLayout from "../main/MainLayout";
import React from "react";
import assert from "assert";

const MISTORE50_CODE = "MISTORE-50-HYUIB234"

const miStoreMarketingText = <>
    Acconsento al trattamento dei miei dati personali da parte di Mi Store Italia, come riportato nell’<a href={"https://static.ubi.datafolio.org/assets/partners/mistore/privacy_information.pdf"}>informativa privacy</a>, per finalità legate alla richiesta di informazioni e/o all’attivazione della promozione.
</>

const MIStoreConsentSettingsWithPromo = {
    introduction: <>
        Gentile utente, se vuoi beneficiare dell’offerta esclusiva di Mi Store Italia, è necessario fornire i seguenti consensi:
    </>,
    partnerMarketing: miStoreMarketingText,
    partnerMarketingMandatory: true
}

const MIStoreConsentSettingsBasic = {
    partnerMarketing: miStoreMarketingText
}

class MIStoreFlavour implements Flavour {
    flavourId = "mistore"
    partnerId = "mistore"
    brandId = "datafolio"

    requireProfile = true
    allowPromotionCode = false
    useReferralCode: string | null
    consentSettings: ConsentSettings

    constructor(isPromo: boolean) {
        if (isPromo) {
            this.useReferralCode = MISTORE50_CODE
            this.consentSettings = MIStoreConsentSettingsWithPromo
        } else {
            this.useReferralCode = null
            this.consentSettings = MIStoreConsentSettingsBasic
        }
    }

    signup(username: string, password: string, countryCode: string, promotionCode: string, profile: Profile, consents: Consents): Promise<unknown> {
        assert (!promotionCode)

        return api.signup({
            username: username,
            password: password,
            countryCode: countryCode,
            partnerId: this.partnerId,
            referralCode: this.useReferralCode,
            profile: profile,
            consents: consents,
        })
    }

    layout(content: JSX.Element | JSX.Element[]): JSX.Element {
        return <MainLayout
            logo={require(`../assets/img/mistore-logo.png`)?.default}
            leftSideIllustration={require('../assets/img/register-illustration-xiaomi.png').default}
        >
            {content}
        </MainLayout>
    }
}

export function enterMIStore(entranceCode: string) {
    return new MIStoreFlavour(entranceCode === 'mistore-escooter-purchase')
}