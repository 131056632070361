var PrivacyPolicy = () => {
    return (
        <div style={{"margin-left": "10%"}}>
            <table width={"80%"} cellSpacing={0} cellPadding={7}>
                <colgroup>
                    <col width={629} />
                </colgroup>
                <tbody>
                <tr>
                    <td width={629} valign="top">
                    </td>
                </tr>
                <tr>
                    <td width={629} valign="top">
                        <p lang="it-IT" align="center">
                            <strong>
                                INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI
                            </strong>
                        </p>
                        <p lang="it-IT" align="center">
                            <strong>AI SENSI DEL REGOLAMENTO UE N. 2016/679</strong>
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
            <table width={"80%"} cellSpacing={0} cellPadding={7}><tr><td>
            <p lang="it-IT" align="justify">
                In applicazione della normativa sul trattamento dei dati personali in
                materia di privacy e del Regolamento UE n. 2016/679 (di seguito GDPR) La
                informiamo che, per effetto della sottoscrizione delle Condizioni generali
                d’uso dell’App di Datafolio S.a.s. (“Sviluppatore”), con sede legale in 165
                avenue de Bretagne - 59000 Lille (Francia) – P.Iva FR31877629493, nel corso
                dello svolgimento del rapporto contrattuale quest'ultima si troverà a
                raccogliere e trattare, in qualità di titolare del trattamento, alcuni Suoi
                dati personali per finalità di erogazione dei servizi richiesti. Le
                finalità e le modalità del trattamento sono meglio specificate nel
                prosieguo del presente documento informativo. l dati raccolti saranno
                trattati sulla base dei principi di correttezza, liceità e trasparenza,
                nonché nel rispetto del principio di minimizzazione dei dati. A tal
                proposito, Le chiediamo il consenso espresso per talune tipologie di
                trattamento dei Suoi dati personali.
            </p>
            <p lang="it-IT" align="justify">
                <u><strong>1. Definizioni</strong></u>
            </p>
            <p lang="it-IT" align="justify">
                Per agevolare la comprensione dell’informativa, si riportano alcune
                definizioni di cui all’art. 4 del GDPR.
            </p>
            <p lang="it-IT" align="justify">
                In particolare, si intende per:
            </p>
            </td></tr></table>
            <table width={"80%"} cellSpacing={0} cellPadding={7}>
                <colgroup>
                    <col width={184} />
                    <col width={430} />
                </colgroup>
                <tbody>
                <tr valign="top">
                    <td width={184}>
                        <p lang="it-IT" align="justify">
                            <u><strong>Interessato</strong></u>
                            :
                        </p>
                    </td>
                    <td width={430}>
                        <p lang="it-IT" align="justify">
                            la persona fisica identificata o identificabile cui si
                            riferiscono i dati personali.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={184}>
                        <p lang="it-IT" align="justify">
                            <u><strong>Consenso dell’interessato</strong></u>
                            :
                        </p>
                    </td>
                    <td width={430}>
                        <p lang="it-IT" align="justify">
                            qualsiasi manifestazione di volontà libera, specifica,
                            informata e inequivocabile dell’interessato, con la quale
                            lo stesso manifesta il proprio assenso, mediante
                            dichiarazione o azione positiva inequivocabile, che i dati
                            personali che lo riguardano siano oggetto di trattamento.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={184}>
                        <p lang="it-IT" align="justify">
                            <u><strong>Trattamento</strong></u>
                            :
                        </p>
                    </td>
                    <td width={430}>
                        <p lang="it-IT" align="justify">
                            qualsiasi operazione o insieme di operazioni, compiute con
                            o senza l’ausilio di processi automatizzati e applicate a
                            dati personali o insiemi di dati personali come la
                            raccolta, la registrazione, l’organizzazione, la
                            strutturazione, la conservazione, l’adattamento o la
                            modifica, l’estrazione, la consultazione, l’uso, la
                            comunicazione mediante trasmissione, diffusione o qualsiasi
                            altra forma di messa a disposizione, il raffronto o
                            l’interconnessione, la limitazione, la cancellazione o la
                            distruzione.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={184}>
                        <p lang="it-IT" align="justify">
                            <u><strong>Dati Personali</strong></u>
                            :
                        </p>
                    </td>
                    <td width={430}>
                        <p lang="it-IT" align="justify">
                            qualsiasi informazione riguardante una persona fisica
                            identificata o identificabile (“interessato”); si considera
                            identificabile la persona fisica che può essere
                            identificata, direttamente o indirettamente, con
                            particolare riferimento a un identificativo come il nome,
                            un numero di identificazione, dati relativi all’ubicazione,
                            un identificativo on line o a uno o più elementi
                            caratteristici della sua identità fisica, fisiologica,
                            genetica, psichica, economica, culturale o sociale.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={184}>
                        <p lang="it-IT" align="justify">
                            <u><strong>Dati Personali Particolari:</strong></u>
                        </p>
                    </td>
                    <td width={430}>
                        <p lang="it-IT" align="justify">
                            dati personali che rivelino l’origine razziale o etnica, le
                            opinioni politiche, le convinzioni religiose o filosofiche,
                            o l’appartenenza sindacale, nonché trattare dati genetici,
                            dati biometrici intesi ad identificare in modo univoco una
                            persona fisica, dati relativi alla salute o alla vita
                            sessuale o all’orientamento sessuale della persona ovvero
                            dati relativi a sentenze o indagini penali.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={184}>
                        <p lang="it-IT" align="justify">
                            <u><strong>Informativa</strong></u>
                            <strong>: </strong>
                        </p>
                    </td>
                    <td width={430}>
                        <p lang="it-IT" align="justify">
                            le informazioni che il titolare del trattamento deve
                            fornire ad ogni interessato, verbalmente o per iscritto
                            quando i dati sono raccolti presso l’interessato stesso
                            oppure presso terzi. L’informativa deve precisare
                            sinteticamente e in modo colloquiale quali sono gli scopi e
                            le modalità del trattamento; se l’interessato è obbligato o
                            no a fornire i dati; quali sono le conseguenze se i dati
                            non vengono forniti; a chi possono essere comunicati o
                            diffusi i dati; quali sono i diritti riconosciuti
                            all’interessato; chi sono il titolare e l’eventuale
                            responsabile del trattamento e dove sono raggiungibili
                            (indirizzo, telefono, fax, ecc.).
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={184}>
                        <p lang="it-IT" align="justify">
                            <u><strong>Titolare del trattamento</strong></u>
                            <strong>:</strong>
                        </p>
                    </td>
                    <td width={430}>
                        <p lang="it-IT" align="justify">
                            la persona fisica o giuridica, l’autorità pubblica, il
                            servizio o altro organismo che, singolarmente o insieme ad
                            altri, determina le finalità e i mezzi del trattamento dei
                            dati personali.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={184}>
                        <p lang="it-IT" align="justify">
                            <u><strong>Responsabile del trattamento</strong></u>
                            <strong>:</strong>
                        </p>
                    </td>
                    <td width={430}>
                        <p lang="it-IT" align="justify">
                            la persona fisica o giuridica, l’autorità pubblica, il
                            servizio o altro organismo che tratta dati personali per
                            conto del Titolare.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={184}>
                        <p lang="it-IT" align="justify">
                            <u>
                                <strong>
                                    Responsabile della Protezione dei Dati – DPO (Data
                                    Protection Officer)
                                </strong>
                            </u>
                            <strong>:</strong>
                        </p>
                    </td>
                    <td width={430}>
                        <p lang="it-IT" align="justify">
                            Il Data Protection Officer (DPO), è una figura introdotta
                            dal GDPR. La sua responsabilità principale è quella di
                            osservare, valutare e organizzare la gestione del
                            trattamento di dati personali (e dunque la loro protezione)
                            all’interno di un’azienda (sia essa pubblica che privata),
                            affinché questi siano trattati nel rispetto delle normative
                            privacy europee e nazionali.
                        </p>
                        <p lang="it-IT" align="justify">
                            La nomina del DPO all’interno di un’azienda è obbligatoria
                            al verificarsi delle seguenti condizioni:
                        </p>
                        <ul>
                            <li>
                                <p lang="it-IT" align="justify">
                                    Il trattamento è effettuato da un’autorità pubblica
                                    o da un organismo pubblico, escluse le autorità
                                    giurisdizionali quando esercitano le loro funzioni
                                    giurisdizionali;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    le attività principali del Titolare del trattamento
                                    o del Responsabile del trattamento consistono in
                                    trattamenti che, per loro natura, ambito di
                                    applicazione e/o finalità, richiedono il
                                    monitoraggio regolare e sistematico degli
                                    interessati su larga scala; oppure
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    le attività principali del Titolare del trattamento
                                    o del Responsabile del trattamento consistono nel
                                    trattamento, su larga scala, di categorie
                                    particolari di dati personali o di dati relativi a
                                    condanne penali e reati.
                                </p>
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={184}>
                        <p lang="it-IT" align="justify">
                            <u><strong>Profilazione</strong></u>
                            <strong>:</strong>
                        </p>
                    </td>
                    <td width={430}>
                        <p lang="it-IT" align="justify">
                            qualsiasi forma di trattamento automatizzato di dati
                            personali consistente nell’utilizzo di dati personali per
                            valutare determinati aspetti personali relativi alla
                            persona fisica, in particolare per analizzare o prevedere
                            aspetti riguardanti il rendimento professionale, la
                            situazione economica, la salute, le preferenze personali,
                            gli interessi, l’affidabilità, il comportamento,
                            l’ubicazione o gli spostamenti di detta persona fisica.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={184}>
                        <p lang="it-IT" align="justify">
                            <u><strong>Pseudonimizzazione</strong></u>
                            <strong>:</strong>
                        </p>
                    </td>
                    <td width={430}>
                        <p lang="it-IT" align="justify">
                            il trattamento dei dati personali in modo tale che gli
                            stessi non possano più essere attribuiti ad un interessato
                            specifico senza l’utilizzo di informazioni aggiuntive,
                            purchè le medesime siano conservate separatamente e
                            soggette a misure tecniche e organizzative tali da
                            assicurare che i dati non siano attribuiti ad una persona
                            fisica identificata o identificabile:
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={184}>
                        <p lang="it-IT" align="justify">
                            <u><strong>Violazione dei dati personali</strong></u>
                            <strong>:</strong>
                        </p>
                    </td>
                    <td width={430}>
                        <p lang="it-IT" align="justify">
                            la violazione di sicurezza che comporta accidentalmente o
                            in modo illecito la distruzione, la perdita, la modifica,
                            la divulgazione non autorizzata o l’accesso ai dati
                            personali trasmessi, conservati o comunque trattati.
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
            <table width={"80%"} cellSpacing={0} cellPadding={7}><tr><td>
            <p lang="it-IT" align="justify">
                <u>
                    <strong>2. Natura dei dati trattati e finalità del trattamento</strong>
                </u>
            </p>
            </td></tr></table>
            <table width={"80%"} cellSpacing={0} cellPadding={7}>
                <colgroup>
                    <col width={19} />
                    <col width={595} />
                </colgroup>
                <tbody>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="justify">
                            <strong>2.1</strong>
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            <strong>
                                Trattamento dei dati personali per finalità di
                                intermediazione commerciale e prevenzione rischi
                            </strong>
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Il trattamento dei Suoi dati personali è diretto
                            all’espletamento da parte di Datafolio delle finalità
                            aziendali quali le attività necessarie ai fini del download
                            e dell’attivazione della App Datafolio, la fornitura dei
                            servizi richiesti dall’interessato tramite la App
                            Datafolio, la predisposizione, stipulazione di
                            proposte/contratti di servizi, di fornitura e di altre
                            prestazioni, l’esercizio e difesa dei diritti di Datafolio,
                            l’adempimento di altri specifici obblighi di legge o
                            contrattuali; l’analisi di nuovi mercati; la gestione e
                            controllo interno; le attività statistiche, la prevenzione
                            rischi.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            In particolare, i dati personali da Lei forniti (dati
                            identificativi e di contatto) e/o rilevati e trasmessi dal
                            Suo dispositivo mobile tramite l’App mobile Datafolio,
                            anche relativi alla Sua geolocalizzazione, sono trattati da
                            Datafolio e il trattamento dei dati è finalizzato
                            all’erogazione dei servizi telematici richiesti quali,
                            nello specifico:
                        </p>
                        <ul>
                            <li>
                                <p lang="it-IT" align="justify">
                                    la conoscenza dell’indice di pericolosità nella
                                    zona in cui il dispositivo mobile è localizzato
                                    ovvero rispetto all’attività e/o al percorso
                                    pianificato in base alle informazioni da Lei
                                    comunicate. Per indice di pericolosità si intende
                                    il rischio che si possano verificare alcuni eventi
                                    quali incidenti, furto, rapina, danneggiamento di
                                    veicoli, eventi atmosferici, contagio, esigenze
                                    personali e/o sanitarie, ecc. La percentuale di
                                    rischio di accadimento dell’evento è determinata
                                    esclusivamente sulla base dei dati statistici
                                    raccolti ed elaborati direttamente da Datafolio;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    l’accesso all’assistenza tecnica e/o a servizi di
                                    urgenza;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    l’erogazione di servizi legati alla mobilità
                                    dell’utente, i servizi di logistica ed assistenza,
                                    i servizi di informazione su fornitori e altri
                                    partner commerciali;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    la fruizione di servizi di segnalazione, in tempo
                                    reale, di condizioni atmosferiche, stima del
                                    traffico, punti di interesse, itinerari di viaggio
                                    dedicati, presenza di zone a rischio sanitario
                                    ovvero esposte ad altra tipologia di rischio,
                                    indice di pericolosità di una zona specifica;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    l’individuazione, in tempo reale, automaticamente o
                                    su richiesta, di strutture sanitarie, esercizi
                                    commerciali, centri di assistenza e altri punti di
                                    interesse (ristoranti, benzinai, ATM, farmacie,
                                    ecc.), convenzionati in prossimità della zona in
                                    cui si trova l’utente e individuazione dei relativi
                                    dati di contatto nonché il percorso per
                                    raggiungerli;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    la memorizzazione della posizione in cui si trova
                                    l’utente e/o dove è ubicato il proprio mezzo di
                                    locomozione limitatamente all’erogazione del
                                    servizio di rinvenimento del veicolo;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    impartire disposizioni logistiche e scambiare con
                                    l’utente altre comunicazioni;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    l’elaborazione di rapporti statistici relativi
                                    all’utente al fine di ottimizzare l'erogazione dei
                                    servizi;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    l’erogazione di servizi finalizzati alla
                                    geolocalizzazione dell’utente ed al rinvenimento
                                    del veicolo e/o mezzo di trasporto e/o locomozione
                                    utilizzato;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    rispondere ai commenti e alle segnalazioni inviate
                                    dagli utenti;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    inviare messaggi e-mail al fine di migliorare il
                                    servizio offerto;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    inviare comunicazioni di carattere tecnico (es.
                                    notifica di interruzioni di servizio);
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    la comunicazione di informazioni e statistiche
                                    relative alla percorrenza (stradale e/o pedonale),
                                    alla condotta di guida ovvero al comportamento di
                                    guida valutato complessivamente rispetto alla
                                    prevenzione del rischio.
                                </p>
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            <u>
                                <strong>
                                    Esistenza di un processo decisionale automatizzato
                                </strong>
                            </u>
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            <strong>
                                La informiamo che Datafolio S.a.s., ai fini
                                dell’erogazione dei predetti servizi, necessita di
                                svolgere delle attività di analisi, attraverso un
                                processo decisionale automatizzato, analizzando i dati
                                di mobilità raccolti dai sensori dello smartphone.
                            </strong>
                        </p>
                        <p lang="it-IT" align="justify">
                            <strong>
                                Questo processo viene svolto utilizzando algoritmi
                                prestabiliti e limitati alle citate esigenze di
                                intermediazione commerciale e di prevenzione dei
                                rischi.
                            </strong>
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            I dati trattati includono anche, a titolo esemplificativo,
                            dati anagrafici (es. nome, cognome, numero di cellulare,
                            e-mail), informazioni sul dispositivo utilizzato (es.
                            sistema operativo installato, modello, ecc.). Inoltre, per
                            consentire il download dell’App Datafolio ed esclusivamente
                            per i motivi connessi alle necessità di erogazione dei
                            servizi richiesti, potrebbe essere richiesto l’accesso ad
                            ulteriori risorse del dispositivo, quali ad esempio
                            posizione, localizzazione in background (che permette il
                            rilevamento continuo dei Suoi spostamenti sui vari mezzi di
                            trasporto anche quando l’applicazione è chiusa o non
                            visibile), SMS, foto/elementi multimediali/file,
                            informazioni sulla connessione internet, seriale
                            dispositivo, versione App Mobile scaricata, ecc. L’App
                            Datafolio potrà utilizzare la fotocamera del dispositivo
                            qualora ai fini dell’accesso e/o utilizzo di servizi fosse
                            necessaria l’acquisizione ed il riconoscimento di un QR
                            Code.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Per le finalità di cui ai servizi fruibili tramite l’App
                            Datafolio, la base giuridica del trattamento è il
                            contratto, ovvero l’atto concludente con cui l’utente
                            decide di avvalersi degli stessi servizi offerti da
                            Datafolio, tramite App, al momento della sua installazione.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            I dati personali sono raccolti direttamente o per il
                            tramite di altri soggetti interessati al rapporto che La
                            riguarda (quali, a titolo di esempio, contraenti di
                            contratti cui Lei risulti il soggetto indicato,
                            beneficiario, coobbligato, fornitori di servizi e altri
                            partner commerciali, ecc.). I Suoi dati posso altresì
                            essere raccolti tramite altri operatori direttamente o
                            indirettamente collegati a Datafolio; soggetti che per
                            soddisfare le Sue richieste (una richiesta di assistenza,
                            una stipulazione di convenzione, una consulenza di uno
                            specialista, ecc.) forniscono informazioni commerciali;
                            organismi associativi e consortili propri del settore
                            commerciale e/o altri soggetti collegati:
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Il conferimento dei Suoi dati personali è necessario alla
                            fornitura dei servizi/prodotti commerciali e/o obbligatorio
                            in base a disposizioni di Legge, regolamento o della
                            normativa comunitaria. L’eventuale rifiuto di fornire i
                            Suoi dati comporterà l’impossibilità per Datafolio di dare
                            esecuzione al servizio. Poiché i Suoi dati personali
                            trattati per dette finalità menzionate possono comprendere
                            anche eventuali dati particolari e/o dati oggetto di
                            particolare tutela, strettamente inerenti alla fornitura
                            dei servizi/prodotti commerciali di cui sopra, Le chiediamo
                            di esprimere il Suo consenso al trattamento.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            La informiamo, inoltre, che i Suoi dati personali, secondo
                            i casi, possono e debbono essere comunicati ad altri
                            soggetti appartenenti al settore commerciale e/o correlati
                            con funzione meramente organizzativa a Datafolio che
                            operano in Italia o all’estero (Paesi dell’Unione Europea e
                            verso Paesi extra Unione Europea che garantiscono un
                            adeguato livello di protezione sostanzialmente equivalente
                            a quello assicurato all’interno dell’Unione in conformità
                            alle disposizioni di legge applicabili, previa stipula
                            delle clausole contrattuali standard previste dalla
                            Commissione Europea) come autonomi titolari per le finalità
                            sopra esposte. A titolo esemplificativo e non esaustivo, i
                            soggetti ai quali i dati personali possono essere
                            comunicati sono: commercianti, fornitori, consulenti,
                            tecnici in genere e enti/società che rilasciano
                            servizi/prodotti correlati o associati ai servizi
                            contrattuali, intermediari, produttori, mediatori
                            commerciali e altri canali di acquisizione di contratti;
                            negozianti, cooperative, società di gestione, enti, studi
                            legali per la gestione di eventuali reclami o controversie,
                            legali, medici, periti, società di servizi a cui siano
                            affidati la gestione, la stipulazione ed il consolidamento
                            di convenzioni; attività convenzionata (eventuali partner
                            di Datafolio) società di servizi informatici e telematici o
                            di archiviazione; società di supporto alle attività di
                            gestione, ivi comprese le società di servizi postali;
                            società di revisione e di consulenza; società di
                            informazione commerciale per rischi finanziari e vari,
                            società di servizi per il controllo delle frodi, società di
                            recupero crediti; organismi associativi e consortili propri
                            del settore commerciale per reciproci scambi con tutte le
                            imprese consorziate; enti gestori commerciali sociali
                            obbligatorie, ecc.; Ministero dell’economia e Finanze –
                            Anagrafe Tributaria, magistratura, forse dell’ordine,
                            consorzi ed attività agricole.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Pertanto, il consenso che Le chiediamo di esprimere
                            concerne anche l’attività svolta da tali soggetti,
                            limitatamente a quanto di stretta competenza in relazione
                            allo specifico rapporto intercorrente tra Lei e Datafolio.
                            Inoltre, i dati potranno essere resi anonimi ed elaborati
                            su base statistica per desumere informazioni strumentali
                            alla fornitura dei servizi di intermediazione commerciale
                            e/o di prevenzione dei rischi e per valutare e migliorare
                            la qualità dei servizi e/o delle prestazioni offerte
                            nell’ambito delle predette attività.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Precisiamo che senza il consenso all’utilizzo dei suoi dati
                            non potremmo fornirLe i servizi/prodotti di cui sopra.
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
            <table width={"80%"} cellSpacing={0} cellPadding={7}>
                <colgroup>
                    <col width={19} />
                    <col width={595} />
                </colgroup>
                <tbody>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="justify">
                            <strong>2.2</strong>
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            <strong>
                                Trattamento dei dati personali per finalità di
                                intermediazione assicurativa
                            </strong>
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            I dati personali da Lei forniti (dati identificativi e di
                            contatto) e/o comunicati dalle compagnie assicurative
                            partner di Datafolio (inerenti al veicolo e informazioni
                            concernenti la tipologia di polizza assicurativa
                            sottoscritta, etc.) e/o rilevati e trasmessi dal Suo
                            dispositivo mobile tramite l’App mobile Datafolio, quali, a
                            titolo esemplificativo, la geolocalizzazione e/o la
                            localizzazione in background (che permette il rilevamento
                            continuo dei Suoi spostamenti sui vari mezzi di trasporto
                            anche quando l’applicazione è chiusa o non visibile) sono
                            trattati da Datafolio (intermediario assicurativo
                            dell’Unione Europea iscritto nell’apposito elenco annesso
                            al R.U.I.) ed il trattamento dei dati è finalizzato alla
                            geolocalizzazione (GPS, Wi-FI, rete GSM) dell’utente e/o
                            del veicolo e/o mezzo di trasporto e/o locomozione
                            utilizzato.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Detto sistema di geolocalizzazione consente di perseguire
                            le seguenti finalità:
                        </p>
                        <ul>
                            <li>
                                <p lang="it-IT" align="justify">
                                    localizzazione dell’utente e/o del veicolo e/o
                                    mezzo di trasporto e/o locomozione utilizzato e la
                                    trasmissione della posizione rilevata: ciò al fine
                                    di impartire disposizioni logistiche e scambiare
                                    con il conducente e/o l’utente altre comunicazioni
                                    in tempo reale;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    elaborazione di un rapporto di guida contenente
                                    informazioni relative a tratta e tempo di
                                    percorrenza, velocità media, distanze e consumo di
                                    carburante, nonché di altre operazioni effettuate
                                    con il veicolo e/o mezzo di trasporto e/o
                                    locomozione e/o dall’utente;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    realizzazione dei servizi di intermediazione
                                    assicurativa ed in particolare:
                                </p>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <p lang="it-IT" align="justify">
                                    fornire i servizi presentati sull’App mobile
                                    Datafolio;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    consentire l’acquisto dei prodotti e/o servizi
                                    assicurativi scelti dall’utente sull’App mobile,
                                    offerti dalle compagnie assicurative e/o altri
                                    partner di Datafolio ed espressamente elencati sul
                                    sito;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    fornire alle compagnie assicurative e/o agli altri
                                    partner di Datafolio le informazioni necessarie
                                    all’erogazione dei servizi da Lei richiesti o con
                                    Lei concordati;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    adempimenti legali nell’osservanza di obblighi di
                                    legge, regolamenti o norme comunitarie a cui
                                    Datafolio è tenuta a conformarsi;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    adempimenti contrattuali necessari per la corretta
                                    gestione, esecuzione e conclusione dei rapporti in
                                    essere, definiti o da definire, quali: studi dei
                                    rischi assicurativi, predisposizione e stipulazione
                                    di polizze assicurative, raccolta di premi,
                                    gestione di sinistri assicurativi o pagamento di
                                    altre prestazioni, tutela dei Suoi diritti di
                                    assicurato;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    servizi di logistica ed assistenza;
                                </p>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <p lang="it-IT" align="justify">
                                    rispondere ai commenti e alle segnalazioni inviate
                                    dagli utenti;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    inviare messaggi e-mail al fine di migliorare il
                                    servizio offerto;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    <strong>
                                        utilizzo nell’ambito del “pay as you drive”
                                        (PAID).
                                    </strong>
                                </p>
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            <u>
                                <strong>
                                    Esistenza di un processo decisionale automatizzato
                                </strong>
                            </u>
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            <strong>
                                La informiamo che Datafolio S.a.s., al fine di valutare
                                e predisporre e calcolare il premio assicurativo e la
                                sua eventuale regolazione, necessita di svolgere delle
                                attività di analisi, attraverso un processo decisionale
                                automatizzato, analizzando i dati di mobilità raccolti
                                dai sensori dello smartphone.
                            </strong>
                        </p>
                        <p lang="it-IT" align="justify">
                            <strong>
                                Questo processo viene svolto utilizzando algoritmi
                                prestabiliti e limitati alla predetta esigenza
                                assicurativa.
                            </strong>
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Per le esclusive finalità assicurative di polizza, è
                            richiesto il Suo consenso espresso.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Nel caso fosse necessario gestire dati personali sensibili
                            strettamente inerenti alla fornitura dei servizi e/o
                            prodotti assicurativi, Le verrà richiesto specifico
                            consenso al momento della raccolta di tali dati che
                            verranno trattati nei limiti in concreto strettamente
                            necessari, dalle relative autorizzazioni di carattere
                            generale rilasciate dal Garante per la protezione dei dati
                            personali. Tali dati potranno però essere successivamente
                            acquisiti e trattati dalle compagnie di assicurazione e/o
                            dagli altri partner di Datafolio, sempre nei limiti in cui
                            tale trattamento sia strumentale per la specifica finalità
                            perseguita dall’operazione o dai servizi richiesti.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Nel perseguimento delle finalità descritte al punto 2.2, i
                            dati possono essere comunicati a taluni dei seguenti
                            soggetti:
                        </p>
                        <ul>
                            <li>
                                <p lang="it-IT" align="justify">
                                    Società di servizi per il quietanzamento; società
                                    di servizi a cui siano affidati la gestione, la
                                    liquidazione ed il pagamento dei sinistri, tra cui
                                    centrale operativa di assistenza, società di
                                    consulenza per tutela giudiziaria, clinica
                                    convenzionata; società di servizi informatici e
                                    telematici o di archiviazione; società di servizi
                                    postali (per trasmissione, imbustamento, trasporto
                                    e smistamento delle comunicazioni alla clientela);
                                    società di revisione e di consulenza; società di
                                    informazione commerciale per rischi finanziari;
                                    società di servizi per il controllo delle frodi;
                                    società di recupero crediti;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    Società che svolgono servizi assicurativi con cui
                                    Datafolio ha stipulato/stipulerà accordi
                                    commerciali (principalmente compagnie di
                                    assicurazione e intermediari assicurativi) per
                                    l’erogazione dei servizi da Lei richiesti. L’elenco
                                    aggiornato delle compagnie di assicurazione e degli
                                    intermediari e partner assicurativi sarà pubblicato
                                    in apposite sezioni/pagine dedicate;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    Società fornitrici di servizi cloud e/o altri
                                    servizi informatici di cui Datafolio si avvale;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    Organismi associativi (ANIA) e consortili propri
                                    del settore assicurativo;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    IVASS, Ministero dell’Industria, del commercio e
                                    dell’Artigianato, CONSAP, UCI, Ministero del Lavoro
                                    e della Previdenza Sociale ed altre banche dati nei
                                    confronti delle quali la comunicazione dei dati è
                                    obbligatoria.
                                </p>
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Inoltre, tenuto conto che i dati forniti, nell’ambito del
                            rapporto assicurativo esistente o da costituire, potranno
                            essere utilizzati per fornire il servizio di
                            preventivazione ed emissione di polizze, a tal fine
                            potranno essere elaborati e confrontati con altre banche
                            dati legittimamente disponibili ed adottando adeguate
                            cautele per garantire la massima riservatezza dei dati
                            stessi. Inoltre, i dati potranno essere resi anonimi ed
                            elaborati su base statistica per desumere informazioni
                            strumentali alla fornitura dei servizi di intermediazione
                            assicurativa e/o di prevenzione dei rischi e per valutare e
                            migliorare la qualità dei servizi e/o delle prestazioni
                            offerte nell’ambito delle predette attività.
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
            <table width={"80%"} cellSpacing={0} cellPadding={7}>
                <colgroup>
                    <col width={19} />
                    <col width={595} />
                </colgroup>
                <tbody>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="justify">
                            <strong>2.3</strong>
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            <strong>
                                Trattamento dei dati personali per ricerche di mercato
                                e/o finalità promozionali
                            </strong>
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Nel caso in cui Lei presti specifico e separato consenso, i
                            Suoi dati potranno essere comunicati a società terze con le
                            quali Datafolio abbia sottoscritto rapporti di
                            collaborazione commerciale, come ad esempio società di
                            marketing, co-marketing, piattaforme pubblicitarie. In tal
                            caso, grazie al Suo consenso, questi soggetti – agendo
                            quali autonomi titolari del trattamento, fornendo separata
                            informativa – potranno utilizzare i dati per inviarLe
                            offerte e proposte riservate. I Suoi dati non saranno
                            diffusi, messi a disposizione o dati in consultazione in
                            alcuna forma a soggetti diversi da quelli indicati al
                            presente paragrafo o a soggetti indeterminati.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            I dati raccolti potranno essere trattati da Datafolio,
                            previo esplicito e libero consenso dell’interessato, per
                        </p>
                        <ul>
                            <li>
                                <p lang="it-IT" align="justify">
                                    finalità di promozione di servizi a valore aggiunto
                                    (es.: informazioni meteo, traffico, etc.);
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    la comunicazione commerciale, l’offerta di prodotti
                                    o servizi, propri o di terzi, l’invio di materiale
                                    pubblicitario, il compimento di ricerche di mercato
                                    e attività di profilazione da parte di Datafolio.
                                    Tali offerte potranno essere veicolate tramite
                                    posta elettronica, canali tradizionali (es. posta
                                    tradizionale) e anche mediante l’uso di telefono
                                    con operatore e/o mediante sistemi automatizzati
                                    (es. SMS, notifiche su App Datafolio);
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    migliorare e sviluppare i servizi forniti
                                    all’utente attraverso elaborazioni elettroniche di
                                    comportamenti ed esigenze dell’utente stesso per
                                    individuare e proporre offerte più vantaggiose e
                                    prodotti di maggiore interesse per il Contraente,
                                    anche sulla base della valutazione del rischio
                                    assicurativo legato alle percorrenze ed agli stili
                                    di guida relativi al veicolo assicurato.
                                </p>
                            </li>
                        </ul>
                    </td>
                </tr>
                </tbody>
            </table>
            <table width={"80%"} cellSpacing={0} cellPadding={7}><tr><td>
            <p lang="it-IT" align="justify">
                <u>
                    <strong>
                        3. Modalità del trattamento e periodo di conservazione dei dati
                    </strong>
                </u>
            </p>
            </td></tr></table>
            <table width={"80%"} cellSpacing={0} cellPadding={7}>
                <colgroup>
                    <col width={19} />
                    <col width={595} />
                </colgroup>
                <tbody>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="justify">
                            3.1
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            I Suoi dati saranno trattati da Datafolio – nella propria
                            qualità di Titolare del trattamento – mediante operazioni o
                            complessi di operazioni, come previste dalla vigente
                            normativa. Il trattamento avverrà anche mediante l'ausilio
                            di mezzi elettronici o comunque automatizzati e sarà svolto
                            direttamente dal Titolare e da soggetti esterni a tale
                            organizzazione in Italia o all'estero, con le modalità
                            previste dalla legge. Tali soggetti sono, a loro volta,
                            impegnati a trattare i dati usando solo modalità e
                            procedure strettamente necessarie per le specifiche
                            finalità indicate nella presente informativa e conformi
                            alla normativa. La informiamo inoltre che, all'interno
                            della Società, i dati saranno trattati dai dipendenti e
                            collaboratori nell'ambito delle rispettive funzioni e in
                            conformità con le istruzioni ricevute, sempre e solo per il
                            conseguimento delle specifiche finalità indicate nella
                            presente informativa.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="justify">
                            3.2
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Tutti i dati personali che La riguardano sono conservati a
                            cura di Datafolio per il tempo necessario e funzionale alla
                            gestione delle finalità del trattamento ivi indicate e
                            comunque fino ad un massimo<em><u> </u></em>di sei mesi per
                            i dati connessi al rilevamento dei Suoi viaggi e
                            spostamenti per finalità di intermediazione commerciale e
                            prevenzione rischi e fino ad un massimo di cinque anni per
                            i dati connessi all’erogazione delle prestazioni sottese
                            alla polizza assicurativa, ivi compresi la durata dei
                            viaggi ed i relativi costi.
                        </p>
                        <p lang="it-IT" align="justify">
                            . I dati di cui non sia necessaria la conservazione per
                            obblighi di legge o per legittimo interesse del Titolare
                            saranno cancellati decorsi i termini connessi ad eventuali
                            obblighi di conservazione di legge o a termini
                            prescrizionali decorrenti dalla data di cessazione delle
                            esigenze correlate alle finalità indicate nella presente
                            informativa; il trattamento dei medesimi viene effettuato
                            anche con l’ausilio di mezzi elettronici e automatizzati di
                            tipo informatico, tramite terminali e personal computer
                            collegati in rete con un elaboratore centrale e, comunque,
                            mediante strumenti idonei a garantire la sicurezza e la
                            riservatezza. Inoltre, i dati potranno essere resi anonimi
                            ed elaborati su base statistica per desumere informazioni
                            strumentali alla fornitura dei servizi di intermediazione
                            commerciale e/o assicurativa e di prevenzione dei rischi e
                            per valutare e migliorare la qualità dei servizi e/o delle
                            prestazioni offerte nell’ambito delle predette attività.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Datafolio si avvale di soggetti/enti esterni per la
                            gestione tecnologica dei dati trattati attraverso la App
                            Datafolio. Tali soggetti opereranno in qualità dì
                            responsabili esterni dei relativi trattamenti delegati
                            dalla Società.
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
            <table width={"80%"} cellSpacing={0} cellPadding={7}><tr><td>
            <p lang="it-IT" align="justify">
                <u>4. </u>
                <u><strong>Titolare, Responsabile del trattamento dei dati</strong></u>
            </p>
            </td></tr></table>
            <table width={"80%"} cellSpacing={0} cellPadding={7}>
                <colgroup>
                    <col width={19} />
                    <col width={595} />
                </colgroup>
                <tbody>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Il Titolare del trattamento dei Suoi dati personali è
                            Datafolio, con sede legale in 165 avenue de Bretagne -
                            59000 Lille (Francia), indirizzo di posta elettronica
                            certificata:
                            <u>
                                <a href="mailto:datafolio.sas@pec.it">
                                    datafolio.sas@pec.it
                                </a>
                            </u>
                            , nella persona del Legale Rappresentante pro tempore.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            I dati da Lei forniti potranno essere trattati da
                            dipendenti e collaboratori di Datafolio debitamente
                            nominati incaricati del trattamento da parte del Titolare.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            L’elenco dei Contitolari e dei Responsabili del trattamento
                            è costantemente aggiornato e può essere richiesto inviando
                            una comunicazione al seguente indirizzo di posta
                            elettronica:
                            <u>
                                <a href="mailto:info@datafolio.io">
                                    info@datafolio.io
                                </a>
                            </u>
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Datafolio ha designato un Responsabile della Protezione dei
                            Dati (DPO), raggiungibile inviando una comunicazione al
                            seguente indirizzo di posta elettronica:
                            <u>
                                <a href="mailto:dpo@datafolio.io">dpo@datafolio.io</a>
                            </u>
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
            <table width={"80%"} cellSpacing={0} cellPadding={7}><tr><td>
            <p lang="it-IT" align="justify">
                <u>5. </u>
                <u><strong>Diritti dell’Interessato </strong></u>
            </p>
            </td></tr></table>
            <table width={"80%"} cellSpacing={0} cellPadding={7}>
                <colgroup>
                    <col width={19} />
                    <col width={595} />
                </colgroup>
                <tbody>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Il Regolamento Le conferisce l’esercizio di specifici
                            diritti, tra cui quelli di chiedere al Titolare del
                            trattamento:
                        </p>
                        <ul>
                            <li>
                                <p lang="it-IT" align="justify">
                                    La conferma che sia o meno in corso un trattamento
                                    dei Suoi dati personali e, in tal caso, di
                                    ottenerne l’accesso (diritto di accesso);
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    La rettifica dei dati personali inesatti o
                                    l’integrazione dei dati personali incompleti
                                    (diritto di rettifica);
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    La cancellazione dei dati stessi, se sussiste uno
                                    dei motivi previsti dal Regolamento (diritto
                                    all’oblio);
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    La limitazione del trattamento quando ricorre una
                                    delle ipotesi previste dal Regolamento (diritto di
                                    limitazione);
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    Di ricevere in un formato strutturato, di uso
                                    comune e leggibile da dispositivo automatico i dati
                                    personali da Lei forniti al Titolare e di
                                    trasmettere tali dati a un altro titolare del
                                    trattamento (diritto alla portabilità);
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    Di avere accesso alle categorie di soggetti terzi
                                    che accedono ai Suoi dati personali e revocare il
                                    consenso a tale trattamento, senza pregiudicare la
                                    liceità del trattamento basata sul consenso
                                    prestato prima della revoca;
                                </p>
                            </li>
                            <li>
                                <p lang="it-IT" align="justify">
                                    Di revocare il consenso al trattamento dei Suoi
                                    dati, in qualsiasi momento, senza pregiudicare la
                                    liceità del trattamento basata sul consenso
                                    prestato prima della revoca e di opporsi in
                                    qualsiasi momento al trattamento per finalità di
                                    marketing e di profilazione (diritto di
                                    opposizione).
                                </p>
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            L’installazione sul proprio dispositivo della App Datafolio
                            determina il conseguente consenso al trattamento dei propri
                            dati tramite la App stessa. Il consenso fornito potrà
                            essere revocato tramite i servizi dedicati.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            In qualsiasi momento si potrà facilmente interrompere tutta
                            la raccolta di informazioni da parte dell’App
                            disinstallando l’applicazione stessa.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            L’esercizio dei diritti non è soggetto ad alcun vincolo di
                            forma ed è gratuito.
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Per esercitare i Suoi diritti è possibile inviare i
                            qualsiasi momento le Sue richieste all’indirizzo pec:
                            <u>
                                <a href="mailto:datafolio.sas@pec.it">
                                    datafolio.sas@pec.it
                                </a>
                            </u>
                        </p>
                    </td>
                </tr>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Anche in considerazione di futuri cambiamenti che potranno
                            intervenire sulla normativa privacy applicabile, Datafolio
                            potrà integrare e/o aggiornare, in tutto o in parte, la
                            presente Informativa. Resta inteso che qualsiasi modifica,
                            integrazione o aggiornamento Le sarà comunicato in
                            conformità alla normativa vigente.
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
            <table width={"80%"} cellSpacing={0} cellPadding={7}><tr><td>
            <p lang="it-IT" align="justify">
                <u>
                    <strong>
                        6. Reclamo all'Autorità Garante per la Protezione dei Dati
                        Personali
                    </strong>
                </u>
            </p>
            </td></tr></table>
            <table width={"80%"} cellSpacing={0} cellPadding={7}>
                <colgroup>
                    <col width={19} />
                    <col width={595} />
                </colgroup>
                <tbody>
                <tr valign="top">
                    <td width={19}>
                        <p lang="it-IT" align="right">
                            •
                        </p>
                    </td>
                    <td width={595}>
                        <p lang="it-IT" align="justify">
                            Fatto salvo ogni altro ricorso amministrativo o
                            giurisdizionale. Lei ha il diritto di proporre reclamo al
                            Garante per la protezione dei dati personali, seguendo le
                            procedure e le indicazioni pubblicate sul sito web
                            ufficiale dell'Autorità su
                            <u>
                                <a href="http://www.garanteprivacy.it/">
                                    www.garanteprivacy.it,
                                </a>
                            </u>
                            qualora ritenga che il trattamento che La riguarda violi il
                            Regolamento.
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}

export default PrivacyPolicy
