import {Flavour, RedirectFlavour} from "./index";
import api, {Consents, Profile} from '../api';
import MainLayout from "../main/MainLayout";
import React from "react";
import {DefaultConsentSettings} from "./standard";

class AssaperloFlavour implements Flavour {
    flavourId: string = "assaperlo"
    partnerId: string = "assaperlo"
    brandId: string = "datafolio"
    requireProfile = false
    allowPromotionCode = true
    consentSettings = DefaultConsentSettings

    subPartnerId: string

    constructor(entranceToken: string) {
        this.subPartnerId = entranceToken
    }

    signup(username: string, password: string, countryCode: string, promotionCode: string, profile: Profile, consents: Consents): Promise<unknown> {
        return api.signup({
            username: username,
            password: password,
            countryCode: countryCode,
            partnerId: this.partnerId,
            partnerSubId: this.subPartnerId,
            referralCode: promotionCode,
            profile: profile,
            consents: consents,
        })
    }

    layout(content: JSX.Element | JSX.Element[]): JSX.Element {
        return <MainLayout
            logo={require(`../assets/img/${this.flavourId}-logo.png`)?.default}
            leftSideIllustration={require('../assets/img/register-illustration.png').default}
        >
            {content}
        </MainLayout>
    }
}

export function enterAssaperlo(entranceToken: string): Flavour {
    if (!entranceToken) {
        return new RedirectFlavour("https://assaperlo.com/merchants/datafolio")
    }
    return new AssaperloFlavour(entranceToken)
}
