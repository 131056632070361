import styles from "./style.module.css";
import React, {useState} from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from "@material-ui/lab/Alert";
import {useParams} from "react-router-dom";
import ErrorContext from "./ErrorContext";


interface MainLayoutProps {
    logo: string
    leftSideIllustration: string
    children: JSX.Element | JSX.Element[]
}

const MainLayout = (props: MainLayoutProps) => {
    const [error, setError] = useState('')

    function clearError() {
        setError('');
    }

    return <div className={styles.mainLayout}>
        <div className={styles.header}>
            <img alt='partner logo' src={props.logo} className={styles.logo} />
        </div>
        <div className={styles.leftSide}>
            <img alt='application screenshot' src={props.leftSideIllustration} />
        </div>
        <div className={styles.content}>
            <ErrorContext.Provider value={{error, setError}}>
                {props.children}
            </ErrorContext.Provider>
        </div>
        <div className={styles.footer}>
            <div className={styles.footerContainer}>
                Powered by datafolio <img alt='datafolio logo' src={require('../assets/img/logo_black.png').default} />
            </div>
        </div>
        <Snackbar open={error ? true : false} autoHideDuration={6000} onClose={clearError}>
            <MuiAlert elevation={6} variant="filled" onClose={clearError} severity="error">
                {error}
            </MuiAlert>
        </Snackbar>
    </div>
}

export default MainLayout
