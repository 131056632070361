import React, {useContext} from 'react';
import global_styles from '../../global_styles.module.css';
import brandings from "../../cfg/brandings";
import {FlavourContext} from "../../flavours";

function DownloadLinks() {
    const {brandId} = useContext(FlavourContext)!
    const branding = brandings[brandId]

    if (branding.downloadLinkType == 'testing') {
        return <>
            {
                branding.androidDownloadLink &&
                    <a href={branding.androidDownloadLink} className={global_styles.submit}>
                        Download for Android
                    </a>
            }
            {
                branding.iOSDownloadLink &&
                <a href={branding.iOSDownloadLink} className={global_styles.submit}>
                    Download for iOS
                </a>
            }
        </>
    }
    if (branding.downloadLinkType == 'store') {
        return <div style={{display: 'flex', alignItems: 'center', width: 53}}>
            <a href={branding.iOSDownloadLink}>
                <svg width="110" height="34">
                    <image xlinkHref={require('../../assets/img/appstore-logo-black-it.svg')?.default}
                           height="34"/>
                </svg>
            </a>

            <a href={branding.androidDownloadLink}>
                <img alt='Disponibile su Google Play'
                     height={51}
                     src='https://play.google.com/intl/en_us/badges/static/images/badges/it_badge_web_generic.png'
                />
            </a>
        </div>
    }
    return <>Unknown download type</>
}

function Download() {
    return (
        <>
            <h1>Download app</h1>
            <p className={global_styles.description}>
                Puoi ora effettuare il download dell'applicazione sul tuo cellulare.
            </p>
            <DownloadLinks />
        </>
    );
}

export default Download;
