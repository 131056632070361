import React, {useState, useEffect} from 'react';
import Amplify from 'aws-amplify';
import i18n from "i18next";
import langs from '../lang';
import {initReactI18next} from "react-i18next";
import {CognitoConfig} from '../cfg/config';
import {Switch, Route, Redirect} from 'react-router-dom';
import Register from '../pages/Register';
import ForgotPassword from '../pages/ForgotPassword';
import Download from '../pages/Download';
import NotFound from '../pages/NotFound';
import PrivacyPolicyEN from "../pages/PrivacyPolicy/EN";
import PrivacyPolicyIT from "../pages/PrivacyPolicy/IT";

import './App.css';
import {FlavourRedirect, ProvideFlavour} from "../flavours";

Amplify.configure(CognitoConfig);

function App() {
  const [lang, setLang] = useState('it');
  const [entranceToken, setEntranceToken] = useState('')

  useEffect(() => {
    i18n
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          translation: langs['en']
        },
        it: {
          translation: langs['it']
        }
      },
      lng: lang,
      fallbackLng: 'it',

      interpolation: {
        escapeValue: false
      }
    });
  }, [lang]);

  return (
    <div className="app">
      <Switch>
        <Route path="/privacy-policy" exact>
          <PrivacyPolicyEN />
        </Route>
        <Route path="/privacy-policy/en" exact>
          <PrivacyPolicyEN />
        </Route>
        <Route path="/privacy-policy/it" exact>
          <PrivacyPolicyIT />
        </Route>
        <Route path="/:flavourId" exact>
          <ProvideFlavour entranceToken={entranceToken}>
            <FlavourRedirect to={`/register`} />
          </ProvideFlavour>
        </Route>
        <Route path="/:flavourId/register" exact>
          <ProvideFlavour entranceToken={entranceToken}>
            <Register />
          </ProvideFlavour>
        </Route>
        <Route path="/:flavourId/forgot-password" exact>
          <ProvideFlavour entranceToken={entranceToken}>
            <ForgotPassword />
          </ProvideFlavour>
        </Route>
        <Route path="/:flavourId/download" exact>
          <ProvideFlavour entranceToken={entranceToken}>
            <Download />
          </ProvideFlavour>
        </Route>
        <Route path="/:flavourId/enter/:entranceToken" exact render={
          (renderProps) => {
            setEntranceToken(renderProps.match.params.entranceToken)
            return <Redirect to={`/${renderProps.match.params.flavourId}/register`} push={false}/>
          }
        } />
        <Route path="/">
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
