import React from 'react';
import styles from './style.module.css';

function NotFound() {
  return (
    <div className={styles.notfound}>
      <div className={styles.errorCode}>404</div>
      <div className={styles.errorMessage}>Page not found.</div>
      <img alt='Datafolio Logo' src={require(`../../assets/img/datafolio-logo.png`).default} className={styles.logo} />
    </div>
  );
}

export default NotFound;
