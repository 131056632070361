export default {
  'forgot password title': 'Recupero password',
}

// export default {
//   // 'register-welcome': 'Benvenuto!',
//   // 'register-text-1': 'Per poter effettuare il download e usare la nostra app, devi prima creare un account.',
//   // 'register-text-2': 'Un codice di validazione ti sarà inviato tramite SMS.',
//   // 'international-code': 'Prefisso',
//   // 'phone-number': 'Cellulare',
//   // 'password': 'Password',
//   // 'confirm-password': 'Conferma Password',
//   // 'send-code': 'Inviami il codice',
//   // 'register-already-registered-text-1': 'Già registrato?',
//   // 'register-already-registered-text-2': 'Clicca qui',
//   // 'register-already-registered-text-3': 'per confermare il tuo numero di telefono.',
//   // 'forgot-password': 'Hai dimenticato la password?',
//   // 'verify-phone-confirm': 'Conferma',
//   // 'verify-phone-text': 'Un codice di validazione a 6 cifre è stato inviato via SMS al numero',
//   // 'resend-code': 'Remanda codice',
//   // 'code': 'Codice',
//   // 'validate': 'Convalida',
//   // 'success-registration': 'Registrazione effettuata!',
//   // 'success-registration-text': 'Puoi ora scaricare l\'applicazione e installarla sul tuo cellulare nella massima sicurezza.',
//   // 'download-app': 'Download App',
//   // 'resume-activation': 'Riprendi l\'attivazione dell\'account',
//   // 'resume-activation-text': 'Se sei già registrato e vuoi proseguire con l\'attivazione del tuo account, inserisci il tuo numero di cellulare per ricevere il codice di validazione.',
//   // 'send-code': 'Invia codice',
//   'forgot password title': 'Recupero password',
//   // 'forgot-password-text': 'Inserisci il tuo numero di cellulare per ricevere un codice e impostare la nuova password.',
//   // 'change-password': 'Cambio password',
//   // 'change-password-text': 'Inserisci il codice di validazione e poi scegli una nuova password',
//   // 'password-rule': 'La tua password deve essere lunga almeno 8 caratteri.',
//   // 'update-password': 'Aggiorna password',
//   // 'forgot-password-success': 'Password aggiornata con successo!',
//   // 'forgot-password-success-text': 'La tua password è stata correttamente impostata.',
//   // 'download-app-text': 'Puoi ora effettuare il download dell\'applicazione sul tuo cellulare.'
// }
