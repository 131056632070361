import {ConsentSettings, Flavour, FlavourFactory} from "./index";
import api, {Consents, Profile} from '../api';
import MainLayout from "../main/MainLayout";
import React from "react";

export const CGUConsent = <>
    Proseguendo nella creazione del mio account, dichiaro di aver letto e accettato i <a
    href={"https://static.ubi.datafolio.org/assets/terms_and_conditions.pdf"}>Termini di servizio</a> e <a
    href={"https://clients.ubi.datafolio.org/privacy-policy/it"}>l'Informativa sulla privacy</a>.
</>

export const DefaultConsentSettings = {
    selfMarketing: <>
        Acconsento al trattamento dei miei dati per finalità promozionali o di marketing, come da <a
        href={"https://clients.ubi.datafolio.org/privacy-policy/it"}>informativa sulla privacy</a>
    </>
}

class StandardFlavour implements Flavour {
    flavourId: string
    partnerId: string
    brandId: string
    requireProfile: boolean
    allowPromotionCode = false
    consentSettings: ConsentSettings

    constructor(flavourId: string, partnerId: string, brandId: string, requireProfile: boolean, consentSettings: ConsentSettings) {
        this.flavourId = flavourId
        this.partnerId = partnerId
        this.brandId = brandId
        this.requireProfile = requireProfile
        this.consentSettings = consentSettings
    }

    signup(username: string, password: string, countryCode: string, promotionCode: string, profile: Profile, consents: Consents): Promise<unknown> {
        return api.signup({
            username: username,
            password: password,
            countryCode: countryCode,
            partnerId: this.partnerId,
            referralCode: promotionCode,
            profile: profile,
            consents: consents,
        })
    }

    layout(content: JSX.Element | JSX.Element[]): JSX.Element {
        return <MainLayout
            logo={require(`../assets/img/${this.flavourId}-logo.png`)?.default}
            leftSideIllustration={require('../assets/img/register-illustration.png').default}
        >
            {content}
        </MainLayout>
    }
}

export function enterStandard(flavourId: string, partnerId: string, brandId: string, requireProfile: boolean, consentSettings: ConsentSettings): FlavourFactory {
    return (_) => new StandardFlavour(flavourId, partnerId, brandId, requireProfile, consentSettings)
}
