import {createContext} from "react";

interface ErrorContextValue {
    error: string
    setError: React.Dispatch<React.SetStateAction<string>>
}

const ErrorContext = createContext<ErrorContextValue | null>(null)

export default ErrorContext
