import React, {ChangeEvent, useContext, useState} from "react";
import api from '../../api';
import global_styles from '../../global_styles.module.css';
import {TextField} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import ErrorContext from "../../main/ErrorContext";

interface ChangePasswordProps {
    countryCode: string
    phoneNumber: string
    setStep: React.Dispatch<React.SetStateAction<string>>
}

const ChangePassword = (props: ChangePasswordProps) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const {setError} = useContext(ErrorContext)!;

    function handleClickShowPassword() {
        setShowPassword(prev => !prev);
    }

    function handleMouseDownPassword() {}



    function handleChangePassword(event: ChangeEvent<HTMLInputElement>) {
        setPassword(event.target.value);
    }

    function handleChangeConfirmPassword(event: ChangeEvent<HTMLInputElement>) {
        setConfirmPassword(event.target.value);
    }

    function handleChangeVerificationCode(event: ChangeEvent<HTMLInputElement>) {
        setVerificationCode(event.target.value);
    }

    function handleSubmitChangePassword(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (loading) {
            return;
        }
        event.preventDefault();
        if (password !== confirmPassword) {
            setErrorConfirmPassword('Le password devono corrispondere.');
            return;
        }
        if (password.length < 8) {
            setErrorPassword('La tua password deve essere lunga almeno 8 caratteri.');
            return;
        }
        setLoading(true);
        api.changePassword(props.countryCode + props.phoneNumber, verificationCode, password)
            .then((response) => {
                setLoading(false);
                console.log(response);
                props.setStep('success');
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                setError('Codice di attivazione o numero di telefono errato.');
            });
    }

    return <>
        <h1>Cambio password</h1>
        <p className={global_styles.description}>
            Inserisci il codice di validazione e poi scegli una nuova password
            <br/>
        </p>
        <form onSubmit={handleSubmitChangePassword}>
            <TextField
                label="Codice"
                onChange={handleChangeVerificationCode}
                value={verificationCode}
                fullWidth
            />
            <FormControl style={{width: '100%', margin: '16px 0 0 0'}} error={errorPassword ? true : false}>
                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                <Input
                    id="standard-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={handleChangePassword}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <FormHelperText id="standard-weight-helper-text">La tua password deve essere lunga almeno 8 caratteri.</FormHelperText>
            </FormControl>
            <FormControl style={{width: '100%', margin: '16px 0 0 0'}} error={errorConfirmPassword ? true : false}>
                <InputLabel htmlFor="standard-adornment-password">Conferma Password</InputLabel>
                <Input
                    id="standard-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={handleChangeConfirmPassword}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <FormHelperText id="standard-weight-helper-text">{errorConfirmPassword}</FormHelperText>
            </FormControl>
            <div className={global_styles.actions}>
                <button className={global_styles.submit} disabled={loading}>Aggiorna password</button>
            </div>
        </form>
    </>
}

export default ChangePassword
