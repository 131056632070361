import React, {useState} from 'react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import EnterPhone from "./EnterCode";
import ChangePassword from "./ChangePassword";
import Success from "./Success";
import assert from "assert";

function ForgotPassword(): JSX.Element {
  const [countryCode, setCountryCode] = useState('+39');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [step, setStep] = useState('enter-phone');

  if (step === 'enter-phone') {
    return <EnterPhone
      countryCode={countryCode}
      setCountryCode={setCountryCode}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      setStep={setStep}
    />
  } else if (step === 'change-password') {
    return <ChangePassword countryCode={countryCode} phoneNumber={phoneNumber} setStep={setStep} />
  } else if (step === 'success') {
    return <Success />
  } else {
    assert(false)
  }
}


  function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}




export default ForgotPassword;
