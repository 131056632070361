import axios from 'axios';
import {CognitoConfig} from '../cfg/config';

export interface Consents {
    selfMarketing: boolean
    partnerMarketing: boolean
}

export interface Profile {
    firstName: string
    lastName: string
    email: string
}

const url = CognitoConfig.API.endpoints[0].endpoint;

export interface SignupParams {
    username: string
    password: string
    countryCode: string

    partnerId: string
    partnerSubId?: string
    referralCode?: string | null

    profile?: Profile
    consents?: Consents
}

class API {
    static signup(params: SignupParams) {
        return axios.post(`${url}v2/public/register`, {
            phone: params.username,
            password: params.password,
            country_code: params.countryCode,
            partner_id: params.partnerId,
            partner_sub_id: params.partnerSubId || null,
            referral_code: params.referralCode || null,
            profile: {
                contact: {
                    first_name: params.profile?.firstName || null,
                    last_name: params.profile?.lastName || null,
                    email: params.profile?.email || null,
                },
                consents: {
                    market_research_purpose: params.consents?.selfMarketing || false,
                    partner_marketing_purpose: params.consents?.partnerMarketing || false,
                },
            }
        });
    }

    static resendActivationCode(phoneNumber: string) {
        return axios.post(`${url}v2/public/resend_code`, {
            "phone": phoneNumber
        });
    }

    static verifyPhoneNumber(phoneNumber: string, activationCode: string) {
        return axios.post(`${url}v2/public/verify_phone`, {
            "phone": phoneNumber,
            "activation_code": activationCode
        });
    }

    static changePassword(phoneNumber: string, activationCode: string, password: string) {
        return axios.post(`${url}v2/public/change_passwd`, {
            "phone": phoneNumber,
            "activation_code": activationCode,
            "password": password
        });
    }

    static getDownloadLink(branding: string) {
        return axios.get(`${url}public/version?partner_id=${branding}`);
    }
}

export default API
