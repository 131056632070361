import React, {ChangeEvent, useContext, useState} from "react";
import api from '../../api';
import global_styles from '../../global_styles.module.css';
import {MenuItem, TextField} from "@material-ui/core";
import ErrorContext from "../../main/ErrorContext";

interface EnterCodeProps {
    countryCode: string
    setCountryCode: React.Dispatch<React.SetStateAction<string>>
    phoneNumber: string
    setPhoneNumber: React.Dispatch<React.SetStateAction<string>>
    setStep: React.Dispatch<React.SetStateAction<string>>
}

const phoneCodes = [
    {
        value: '+39',
        label: '🇮🇹 +39'
    },
    {
        value: '+33',
        label: '🇫🇷 +33'
    },
];

const EnterPhone = (props: EnterCodeProps) => {
    const [loading, setLoading] = useState(false)
    const {setError} = useContext(ErrorContext)!;

    function handleSubmitForgotPassword(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoading(true);
        api.resendActivationCode(props.countryCode + props.phoneNumber)
            .then((response) => {
                setLoading(false);
                props.setStep('change-password');
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setError('Numero di telefono non trovato.');
                console.log(error.response)
            });
    }

    function handleChangeCountryCode(event: ChangeEvent<HTMLInputElement>) {
        props.setCountryCode(event.target.value);
    }

    function handleChangePhoneNumber(event: ChangeEvent<HTMLInputElement>) {
        props.setPhoneNumber(event.target.value);
    }

    return <>
        <h1>Recupero password</h1>
        <p className={global_styles.description}>
            Inserisci il tuo numero di cellulare per ricevere un codice e impostare la nuova password.
        </p>
        <form onSubmit={handleSubmitForgotPassword}>
            <div style={{width: '50%', padding: '0 8px 0 0', boxSizing: 'border-box'}}>
                <TextField label="Codice" select onChange={handleChangeCountryCode} value={props.countryCode} fullWidth>
                    {phoneCodes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <div style={{width: '50%', padding: '0 0 0 8px', boxSizing: 'border-box'}}>
                <TextField label="Cellulare" onChange={handleChangePhoneNumber} value={props.phoneNumber} fullWidth />
            </div>
            <div className={global_styles.actions}>
                <button className={global_styles.submit} disabled={loading}>Inviami il codice</button>
            </div>
        </form>
    </>
}

export default EnterPhone
