import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import styles from './styles.module.css'
import global_styles from '../../global_styles.module.css';
import {Checkbox, FormControlLabel, MenuItem, TextField, Typography} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import {Link} from "react-router-dom";
import ErrorContext from "../../main/ErrorContext";
import {FlavourContext} from "../../flavours";
import {CGUConsent} from "../../flavours/standard";

interface CreateAccountProps {
    countryCode: string
    setCountryCode: React.Dispatch<React.SetStateAction<string>>
    phoneNumber: string
    setPhoneNumber: React.Dispatch<React.SetStateAction<string>>
    setStep: React.Dispatch<React.SetStateAction<string>>
}

interface ConsentProps {
    consent: boolean
    setConsent: React.Dispatch<React.SetStateAction<boolean>>
    label: JSX.Element | null | undefined
    error?: boolean
}

const Consent = (props: ConsentProps) => {
    if (!props.label) {
        return <></>
    }

    return <div>
        <FormControlLabel
            control={
                <Checkbox
                    checked={props.consent}
                    onChange={(e) => (props.setConsent(e.target.checked))}
                    style={{transform: "scale(0.6)", padding: 0}}
                />
            }
            style={{alignItems: "start"}}
            label={
                <Typography
                    style={(props.error && !props.consent) ? {color: "red"} : {}}
                    className={styles.consent}
                >
                    {props.label}
                </Typography>
            }
        />
    </div>
}

function arrayEquals(a: any, b: any): boolean {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

const CreateAccount = (props: CreateAccountProps) => {
    const flavour = useContext(FlavourContext)!;

    const [phoneError, setPhoneError] = useState('')
    useEffect(() => setPhoneError(''), [props.phoneNumber])

    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    useEffect(() => setFirstNameError(''), [firstName])

    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState('')
    useEffect(() => setLastNameError(''), [lastName])

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('')
    useEffect(() => setEmailError(''), [email])

    const [promotionCode, setPromotionCode] = useState('')
    const [promotionCodeError, setPromotionCodeError] = useState('')
    useEffect(() => setPromotionCodeError(''), [promotionCode])

    const [cgu, setCgu] = useState(false);
    const [selfMarketing, setSelfMarketing] = useState(false);
    const [partnerMarketing, setPartnerMarketing] = useState(false);
    const [consentError, setConsentError] = useState("")
    useEffect(() => setConsentError(''), [cgu, selfMarketing, partnerMarketing])

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorPassword, setErrorPassword] = useState('');
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const {setError} = useContext(ErrorContext)!;

    const phoneCodes = [
        {
            value: '+39',
            label: '🇮🇹 +39'
        },
        {
            value: '+33',
            label: '🇫🇷 +33'
        },
    ];

    function handleClickShowPassword() {
        setShowPassword(prev => !prev);
    }

    function handleMouseDownPassword() {}

    function handleChangeCountryCode(event: ChangeEvent<HTMLInputElement>) {
        props.setCountryCode(event.target.value);
    }

    function handleChangePhoneNumber(event: ChangeEvent<HTMLInputElement>) {
        props.setPhoneNumber(event.target.value);
    }

    function handleChangePassword(event: ChangeEvent<HTMLInputElement>) {
        setPassword(event.target.value);
        setErrorPassword('');
    }

    function handleChangeConfirmPassword(event: ChangeEvent<HTMLInputElement>) {
        setConfirmPassword(event.target.value);
        setErrorConfirmPassword('');
    }

    function handleSubmitRegister(event: React.FormEvent<HTMLFormElement>) {
        if (loading) {
            return;
        }

        event.preventDefault();
        var error = false
        if (!cgu) {
            setConsentError('Per poter procedere con la creazione di un account, è necessario fornire i consensi evidenziati.')
            error = true
        }

        if (!partnerMarketing && flavour.consentSettings.partnerMarketingMandatory) {
            setConsentError('Per poter procedere con la creazione di un account, è necessario fornire i consensi evidenziati.')
            error = true
        }

        if (!props.phoneNumber) {
            setPhoneError('Necessario')
            error = true
        }

        if (flavour.requireProfile && !firstName) {
            setFirstNameError('Necessario')
            error = true
        }

        if (flavour.requireProfile && !lastName) {
            setLastNameError('Necessario')
            error = true
        }

        if (flavour.requireProfile && !email) {
            setEmailError('Necessario')
            error = true
        }

        if (password !== confirmPassword) {
            setErrorConfirmPassword('Le password devono corrispondere.');
            error = true
        }

        if (password.length < 8) {
            setErrorPassword('La tua password deve essere lunga almeno 8 caratteri.');
            error = true
        }

        if (error) {
            return
        }

        setLoading(true);
        flavour.signup(
            props.countryCode + props.phoneNumber,
            password,
            props.countryCode === '+39' ? 'IT' : 'FR',
            promotionCode,
            {firstName, lastName, email},
            {selfMarketing, partnerMarketing},
        ).then((_) => {
            setLoading(false);
            props.setStep('confirm');
        })
        .catch((error) => {
            console.log(error.response)
            setLoading(false);
            if (error.response.data.detail[0].code === 'account_already_exists') {
                setError('Utente già esistente.');
                setPhoneError('Utente già esistente.')
            } else if (error.response.data.detail[0].code === 'unknown_code') {
                setError('Codice promozionale non valido.');
                setPromotionCodeError('Codice promozionale non valido.')
            } else if (arrayEquals(error.response.data.detail[0].loc, ['body', 'profile', 'contact', 'email'])) {
                setError('Dati non validi')
                setEmailError('Non valido')
            } else if (arrayEquals(error.response.data.detail[0].loc, ['body', 'profile', 'contact', 'first_name'])) {
                setError('Dati non validi')
                setFirstNameError('Non valido')
            } else if (arrayEquals(error.response.data.detail[0].loc, ['body', 'profile', 'contact', 'last_name'])) {
                setError('Dati non validi')
                setLastNameError('Non valido')
            } else if (arrayEquals(error.response.data.detail[0].loc, ['body', 'phone'])) {
                setError('Dati non validi')
                setPhoneError('Non valido')
            }
            return Promise.reject()
        }).catch((error) => {
            console.log(error)
            setError('Unknown Error.')
        })
    }

    return <>
        <div className={global_styles.contentTitle}>Benvenuto!</div>
        <p className={global_styles.description}>
            Per poter effettuare il download e usare la nostra app, devi prima creare un account.
            <br/>
            Un codice di validazione ti sarà inviato tramite SMS.
        </p>
        <form className={global_styles.contentForm} onSubmit={handleSubmitRegister}>
            {
                flavour.requireProfile && <>
                    <FormControl style={{width: '50%', padding: '0 8px 0 0', boxSizing: 'border-box'}} error={firstNameError ? true : false}>
                        <TextField label="Nome" onChange={e => setFirstName(e.target.value)} value={firstName} fullWidth />
                        <FormHelperText id="standard-weight-helper-text">{firstNameError}</FormHelperText>
                    </FormControl>
                    <FormControl style={{width: '50%', padding: '0 8px 0 0', boxSizing: 'border-box'}} error={lastNameError ? true : false}>
                        <TextField label="Cognome" onChange={e => setLastName(e.target.value)} value={lastName} fullWidth />
                        <FormHelperText id="standard-weight-helper-text">{lastNameError}</FormHelperText>
                    </FormControl>
                    <FormControl style={{width: '100%', padding: '16px 8px 0 0', boxSizing: 'border-box'}} error={emailError ? true : false}>
                        <TextField label="Email" onChange={e => setEmail(e.target.value)} value={email} fullWidth />
                        <FormHelperText id="standard-weight-helper-text">{emailError}</FormHelperText>
                    </FormControl>
                </>
            }
            <div style={{width: '50%', padding: '16px 8px 0 0', boxSizing: 'border-box'}}>
                <TextField label="Prefisso" select onChange={handleChangeCountryCode} value={props.countryCode} fullWidth>
                    {phoneCodes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <FormControl style={{width: '50%', padding: '16px 0 0 8px', boxSizing: 'border-box'}} error={phoneError ? true : false}>
                <TextField label="Cellulare" onChange={handleChangePhoneNumber} value={props.phoneNumber} fullWidth />
                <FormHelperText id="standard-weight-helper-text">{phoneError}</FormHelperText>
            </FormControl>
            <FormControl style={{width: '100%', margin: '16px 0 0 0'}} error={errorPassword ? true : false}>
                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                <Input
                    id="standard-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={handleChangePassword}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <FormHelperText id="standard-weight-helper-text">La tua password deve essere lunga almeno 8 caratteri.</FormHelperText>
            </FormControl>
            <FormControl style={{width: '100%', margin: '16px 0 0 0'}} error={errorConfirmPassword ? true : false}>
                <InputLabel htmlFor="standard-adornment-password">Conferma Password</InputLabel>
                <Input
                    id="standard-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={handleChangeConfirmPassword}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <FormHelperText id="standard-weight-helper-text">{errorConfirmPassword}</FormHelperText>
            </FormControl>
            {
                flavour.allowPromotionCode &&
                <FormControl style={{width: '100%', padding: '16px 8px 0 0', boxSizing: 'border-box'}} error={promotionCodeError ? true : false}>
                    <TextField label="Promotion Code" onChange={e => setPromotionCode(e.target.value.toUpperCase())} value={promotionCode} fullWidth />
                    <FormHelperText id="standard-weight-helper-text">{promotionCodeError}</FormHelperText>
                </FormControl>
            }
            <div className={styles.consentContainer}>
                {
                    flavour.consentSettings.introduction && <div className={styles.consent}>
                        {flavour.consentSettings.introduction}
                    </div>
                }
                <Consent consent={cgu} setConsent={setCgu} label={CGUConsent} error={consentError ? true : false} />
                <Consent
                    consent={selfMarketing}
                    setConsent={setSelfMarketing}
                    label={flavour.consentSettings.selfMarketing}
                />
                <Consent
                    consent={partnerMarketing}
                    setConsent={setPartnerMarketing}
                    label={flavour.consentSettings.partnerMarketing}
                    error={(flavour.consentSettings.partnerMarketingMandatory && consentError) ? true : false}
                />
                {consentError && <div className={styles.consentError}>{consentError}</div>}
            </div>
            <div className={global_styles.actions}>
                <button className={global_styles.submit} disabled={loading}>Inviami il codice</button>
            </div>
        </form>
        <p />
        <p className={global_styles.description}>
            Già registrato? <Link to={"download"}>Clicca qui</Link> per scaricare l'applicazione.
        </p>
        <p className={global_styles.description}>
            <Link to="forgot-password">Hai dimenticato la password?</Link>
        </p>
    </>
}

export default CreateAccount