import React, {createContext, useContext} from "react";
import NotFound from "../pages/NotFound";
import {Redirect, useParams} from 'react-router-dom';
import {DefaultConsentSettings, enterStandard} from "./standard";
import {enterAssaperlo} from "./assaperlo";
import {Consents, Profile} from "../api";
import {enterMIStore} from "./mistore";
import {ArgConsentSettings} from "./arg";

export interface Flavour {
    flavourId: string
    partnerId: string
    brandId: string

    requireProfile: boolean
    allowPromotionCode: boolean
    consentSettings: ConsentSettings

    signup(username: string, password: string, countryCode: string, promotionCode: string, profile: Profile, consents: Consents): Promise<unknown>
    layout(content: JSX.Element | JSX.Element[]): JSX.Element
}

export interface ConsentStates {
    cguConsent: boolean
    setCguConsent: React.Dispatch<React.SetStateAction<boolean>>
    selfMarketing: boolean
    setSelfMarketing: React.Dispatch<React.SetStateAction<boolean>>
    partnerMarketing: boolean
    setPartnerMarketing: React.Dispatch<React.SetStateAction<boolean>>
}

export interface ConsentSettings {
    introduction?: JSX.Element
    selfMarketing?: JSX.Element
    partnerMarketing?: JSX.Element
    partnerMarketingMandatory?: boolean
}

export class RedirectFlavour implements Flavour {
    flavourId = ""
    partnerId = ""
    brandId = ""
    requireProfile = false
    allowPromotionCode = false
    consentSettings = {}

    redirectTo: string

    constructor(redirectTo: string) {
        this.redirectTo = redirectTo
    }

    signup(username: string, password: string, countryCode: string, promotionCode: string, profile: Profile, consents: Consents): Promise<unknown> {
        return Promise.reject()
    }

    layout(content: JSX.Element | JSX.Element[]): JSX.Element {
        window.location.href = this.redirectTo
        return <div>Redirecting to partner portal...</div>
    }
}

export interface FlavourFactory {
    (entranceToken: string): Flavour
}

interface FlavourFactories {
    [x: string]: FlavourFactory
}

const flavoursFactories: FlavourFactories = {
    "finital": enterStandard("finital", "finital", "finital", false, DefaultConsentSettings),
    "arg": enterStandard("arg", "arg", "datafolio", true, ArgConsentSettings),
    "assaperlo": enterAssaperlo,
    "datafolio": enterStandard("datafolio", "datafolio", "datafolio", false, DefaultConsentSettings),
    "6sicuro": enterStandard("6sicuro", "6sicuro", "6sicuro", false, {}),
    'giuriolo': enterStandard("giuriolo", "giuriolo", "datafolio", false, DefaultConsentSettings),
    "internaltesting": enterStandard("internaltesting", "internaltesting", "datafolio", false, DefaultConsentSettings),
    "mistore": enterMIStore,
}

export const FlavourContext = createContext<Flavour | null>(null)

interface ProvideFlavourProps {
    entranceToken: string,
    children: JSX.Element | JSX.Element[]
}

export function ProvideFlavour(props: ProvideFlavourProps): JSX.Element {
    const {flavourId} = useParams<{flavourId: string}>()
    const flavourFactory = flavoursFactories[flavourId]
    if (!flavourFactory) {
        return <NotFound />
    }

    let fav = document.getElementById('favicon') as HTMLLinkElement;
    if (fav) {
        fav.href = `${window.location.origin}/${flavourId}.ico`;
    }

    const flavour = flavourFactory(props.entranceToken)
    return <FlavourContext.Provider value={flavour}>
        {flavour.layout(props.children)}
    </FlavourContext.Provider>
}

interface FlavourRedirectProps {
    to: string
}

export function FlavourRedirect(props: FlavourRedirectProps): JSX.Element {
    const flavourId = useContext(FlavourContext)!.flavourId

    return <Redirect to={`/${flavourId}${props.to}`} />
}