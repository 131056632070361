import React, {useState} from 'react';

import CreateAccount from "./CreateAccount";
import ConfirmPhone from "./ConfirmPhone";
import assert from "assert";

function Register(): JSX.Element {
  const [step, setStep] = useState('register');
  const [countryCode, setCountryCode] = useState('+39');
  const [phoneNumber, setPhoneNumber] = useState('');

  if (step === 'register') {
    return <CreateAccount
      countryCode={countryCode}
      setCountryCode={setCountryCode}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      setStep={setStep}
    />
  } else if (step === 'confirm') {
    return <ConfirmPhone
      countryCode={countryCode}
      phoneNumber={phoneNumber}
      setStep={setStep}
    />
  } else {
    assert(false)
  }
}

export default Register;
