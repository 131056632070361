interface Branding {
    downloadLinkType: string,
    iOSDownloadLink: string,
    androidDownloadLink: string,
}

interface Brandings {
    [index: string]: Branding
}

const brandings: Brandings = {
    'datafolio': {
        downloadLinkType: "store",
        iOSDownloadLink: "https://apps.apple.com/us/app/datafolio/id1591445121",
        androidDownloadLink: "https://play.google.com/store/apps/details?id=com.datafolio.ubi.datafolio",
    },
    'd4f': {
        downloadLinkType: "store",
        iOSDownloadLink: "",
        androidDownloadLink: "",
    },
    'finital': {
        downloadLinkType: "store",
        iOSDownloadLink: "https://apps.apple.com/it/app/instant-finital-by-datafolio/id1606902382",
        androidDownloadLink: "https://play.google.com/store/apps/details?id=com.datafolio.ubi.finital",
    },
    '6sicuro': {
        downloadLinkType: "store",
        iOSDownloadLink: "https://apps.apple.com/it/app/6sicuro-e-vai/id1613689847",
        androidDownloadLink: "https://play.google.com/store/apps/details?id=com.datafolio.ubi.seisicuro",
    },
}

export default brandings
