import React from "react";
import global_styles from '../../global_styles.module.css';

const Success = () => {
    return <>
        <h1>Password aggiornata con successo!</h1>
        <p className={global_styles.description}>
            La tua password è stata correttamente impostata.
        </p>
    </>
}

export default Success
