export default {
  'register_welcome': 'Benvenuto!',
  'register_text_1': 'Per poter effettuare il download e usare la nostra app, devi prima creare un account.',
  'register_text_2': 'Un codice di validazione ti sarà inviato tramite SMS.',
  'international_code': 'Prefisso',
  'phone_number': 'Cellulare',
  'password': 'Password',
  'confirm_password': 'Conferma Password',
  'send_code': 'Inviami il codice',
  'register_already_registered_text_1': 'Già registrato?',
  'register_already_registered_text_2': 'Clicca qui',
  'register_already_registered_text_3': 'per confermare il tuo numero di telefono.',
  'forgot_password': 'Hai dimenticato la password?',
  'verify_phone_confirm': 'Conferma',
  'verify_phone_text': 'Un codice di validazione a 6 cifre è stato inviato via SMS al numero',
  'resend_code': 'Remanda codice',
  'code': 'Codice',
  'validate': 'Convalida',
  'success_registration': 'Registrazione effettuata!',
  'success_registration_text': 'Puoi ora scaricare l\'applicazione e installarla sul tuo cellulare nella massima sicurezza.',
  'download_app': 'Download App',
  'resume_activation': 'Riprendi l\'attivazione dell\'account',
  'resume_activation_text': 'Se sei già registrato e vuoi proseguire con l\'attivazione del tuo account, inserisci il tuo numero di cellulare per ricevere il codice di validazione.',
  'forgot_password_title': 'Recupero password',
  'forgot_password_text': 'Inserisci il tuo numero di cellulare per ricevere un codice e impostare la nuova password.',
  'change_password': 'Cambio password',
  'change_password_text': 'Inserisci il codice di validazione e poi scegli una nuova password',
  'password_rule': 'La tua password deve essere lunga almeno 8 caratteri.',
  'update_password': 'Aggiorna password',
  'forgot_password_success': 'Password aggiornata con successo!',
  'forgot_password_success_text': 'La tua password è stata correttamente impostata.',
  'download_app_text': 'Puoi ora effettuare il download dell\'applicazione sul tuo cellulare.'
}
