import React, {ChangeEvent, useState} from "react";
import api from '../../api';
import global_styles from '../../global_styles.module.css';
import {TextField} from "@material-ui/core";
import {useHistory, useParams} from "react-router-dom";

interface ConfirmPhoneProps {
    countryCode: string
    phoneNumber: string
    setStep: React.Dispatch<React.SetStateAction<string>>
}

const ConfirmPhone = (props: ConfirmPhoneProps) => {
    const [verificationCode, setVerificationCode] = useState('');
    const [loading, setLoading] = useState(false);
    const {flavourId} = useParams<{ flavourId: string }>();
    const history = useHistory()

    function handleClickReset() {
        props.setStep('register');
    }

    function handleChangeVerificationCode(event: ChangeEvent<HTMLInputElement>) {
        setVerificationCode(event.target.value);
    }

    function handleSubmitVerificationCode(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoading(true);
        api.verifyPhoneNumber(props.countryCode + props.phoneNumber, verificationCode).then(
            (_) => {
                setLoading(false);
                history.push(`/${flavourId}/download`);
            }
        ).catch(
            (error) => {
                setLoading(false);
                console.log(error);
            }
        );
    }

    return <>
        <h1>Conferma</h1>
        <p className={global_styles.description}>
            Un codice di validazione a 6 cifre è stato inviato via SMS al numero {props.countryCode}{props.phoneNumber}.
            <button onClick={handleClickReset} className={global_styles.backBtn}>Rimanda codice</button>.
        </p>
        <form onSubmit={handleSubmitVerificationCode} className={global_styles.contentForm}>
            <TextField
                label="Codice"
                onChange={handleChangeVerificationCode}
                value={verificationCode}
                fullWidth
            />
            <div className={global_styles.actions}>
                <button className={global_styles.submit} disabled={loading}>Convalida</button>
            </div>
        </form>
    </>
}

export default ConfirmPhone
